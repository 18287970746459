@import "../../../../../Import/SCSS/classes";

.WidgetTrending
{
    .WidgetError
    {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
    }
}

.WidgetTrendingItem
{
    @include list-item;

    &.Loaded
    {
        @include list-item-interactive;
    }
}

.WidgetTrendingItems
{
    @include list-items;
}

.WidgetTrendingItemIcon
{
    padding: 10px;
    width: 40px !important;
    height: 40px !important;
    color: $color-white;
    background-color: $color-purple;
}

.WidgetTrendingItemSpinner
{
    padding: 10px;
    color: $color-white;

    .Spinner,
    .SpinnerIcon
    {
        display: block;
        width: 40px !important;
        height: 40px !important;
    }
}