
@import '../../../Import/SCSS/classes';

.Progress {

    @include no-select;

    position: relative;
    height: 14px;
    line-height: 14px;
    overflow: hidden;

}

.ProgressBar {

    position: relative;
    height: 4px;
    margin: 5px;
    background: color-white( 0.5 );
    border-radius: 4px;
    overflow: hidden;

    .Progress.Full & {

        margin: 5px 0;

    }

}

.ProgressBarFill {

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: inherit;

}

.ProgressLeft,
.ProgressRight {

    position: relative;
    top: -1px;
    height: 14px;
    min-width: 30px;
    color: currentColor;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

}

.ProgressLeft {

    float: left;
    padding-right: 5px;
    text-align: right;

    .Progress.Full & {

        display: none;

    }

}

.ProgressRight {

    float: right;
    padding-left: 5px;
    text-align: left;

    .Progress.Full & {

        display: none;

    }

}