
@import "../../../Import/SCSS/classes";

.SpinnerContainer {

    @include fade-up;
    @include no-select;

    &.Absolute {

        @include overlay;

        z-index: 1000;

        .Spinner {

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);

        }

    }

    &.Relative {

        display: inline-block;
        position: relative;
        vertical-align: middle;

        .Spinner {

            position: relative;

        }

    }

}

.SpinnerIcon {

    display: block;
    animation: spinnerRotate 2s linear infinite both;

}

@include keyframes( spinnerRotate ) {

    0% {

        transform: rotate(0deg);

    }

    100% {

        transform: rotate(360deg);

    }

} 