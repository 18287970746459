
@import "Import/SCSS/classes";

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

*
{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body
{
    height: 100%;
    overflow: hidden;
}

body,
input,
select,
textarea
{
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b,
strong
{
    font-weight: $font-weight-bold;
}

h1
{
    @include heading-1;
}

h2
{
    @include heading-2;
}

h3
{
    @include heading-3;
}

@include keyframes(fadeDown)
{
    0%
    {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    100%
    {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@include keyframes(fadeIn)
{

    0%
    {
        opacity: 0;
    }

    100%
    {
        opacity: 1;
    }
}

@include keyframes(fadeLeft)
{
    0%
    {
        opacity: 0;
        transform: translate3d(20px, 0, 0);
    }

    100%
    {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@include keyframes(fadeOut)
{
    0%
    {
        opacity: 1;
    }

    100%
    {
        opacity: 0;
    }
}

@include keyframes(fadeRight)
{
    0%
    {
        opacity: 0;
        transform: translate3d(-20px, 0, 0);
    }

    100%
    {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@include keyframes(fadeUp)
{
    0%
    {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
    }

    100%
    {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@include keyframes(fadeDownOut)
{
    0%
    {
        opacity: 1;
        transform: translateY(0%);
    }

    100%
    {
        opacity: 0;
        transform: translateY(100%);
    }
}

@include keyframes(fadeDownIn)
{
    0%
    {
        opacity: 0;
        transform: translateY(-100);
    }

    100%
    {
        opacity: 1;
        transform: translateY(0%);
    }
}

@include keyframes(fadeUpOut)
{
    0%
    {
        opacity: 1;
        transform: translateY(0%);
    }

    100%
    {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@include keyframes(fadeUpIn)
{
    0%
    {
        opacity: 0;
        transform: translateY(100%);
    }

    100%
    {
        opacity: 1;
        transform: translateY(0%);
    }
}