@import '../../../../Import/SCSS/classes';

.WysiwygField
{
    @include field;

    position: relative;

    label 
    {
        @include field-label;
    }

    .WysiwygEditor
    {
        max-height: 200px;
        overflow: auto;

        blockquote
        {
            display: block;
            position: relative;
            margin: 20px 0;
            font-size: 1.2em;
            line-height: 1.3;
            quotes: "“" "”" "‘" "’";

            &:after
            {
                content: close-quote;
            }

            &:before
            {
                content: open-quote;
            }

            *
            {
                display: inline;
                margin: 0;
            }
        }

        p
        {
            margin: 22px 0;
        }

        pre
        {
            display: block;
            position: relative;
            margin: 0;
            padding: 10px;
            font-family: 'Courier New', Courier, monospace;
            font-size: 0.8em;
            line-height: 1.6;
            opacity: 0.75;
            overflow: hidden;
            
            &:after,
            &:before
            {
                @include overlay;

                pointer-events: none;
                content: "";
            }

            &:after
            {
                border: 1px solid currentColor;
                opacity: 0.2;
            }

            &:before
            {
                background-color: currentColor;
                opacity: 0.05;
            }
        }

        ol
        {
            counter-reset: list-number;

            li
            {
                position: relative;
                padding-left: 20px;
                counter-increment: list-number;

                &:before
                {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: counter(list-number) ". ";
                }
            }
        }

        ul
        {
            li
            {
                position: relative;
                padding-left: 20px;

                &:before
                {
                    display: block;
                    position: absolute;
                    top: 0.55em;
                    left: 0.15em;
                    width: 0.25em;
                    height: 0.25em;
                    border-radius: 100%;
                    border: 1px solid currentColor;
                    content: "";
                }
            }
        }
    }
}