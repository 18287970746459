
@import "../../../../../Import/SCSS/classes";

.WidgetQuestion
{
    &.NavLeft .WidgetQuestionUser.Active
    {
        @include fade-right;
    }

    &.NavRight .WidgetQuestionUser.Active
    {
        @include fade-left;
    }

    &.Shrink1
    {
        .UserAvatar
        {
            display: none;
        }
    }

    &.Shrink2
    {
        .TextareaField
        {
            display: inline-block;
            width: calc(100% - 110px);
            margin: 10px 10px 10px 0;
            vertical-align: middle;
        }

        .WidgetQuestionUserLink
        {
            text-align: left;
        }
    }

}

.WidgetQuestionConfirm
{
    @include fade-up;
    @include overlay;

    background-color: color-gray-light( 0.9 );

    span
    {
        display: block;
        position: relative;
        top: 50%;
        font-family: $font-family-headline;
        font-weight: $font-weight-headline;
        text-align: center;
        transform: translateY(-50%);
    }
}

.WidgetQuestionUser
{
    @include overlay;

    opacity: 0;
    transition: opacity $duration $timing-in-out;
    overflow: hidden;

    &.Active
    {
        z-index: 10;
    }

    .TextareaField
    {
        margin: 20px 0 10px;
    }
}

.WidgetQuestionUserContent
{
    position: relative;
    top: 50%;
    margin: 0 auto;
    max-width: 300px;
    text-align: center;
    transform: translateY(-50%);

    h2,
    p
    {
        margin: 0 !important;
    }
}