@import "../../../Import/SCSS/classes";

.ViewWidget
{
    @include overlay;
}

.Widget
{
    @include fade-up;
    @include no-select;
    @include overlay;
    overflow: hidden;

    @include hover
    {
        & > .WidgetToolbarWrapper
        {
            opacity: 1;
            & > .WidgetToolbar
            {
                opacity: 0.5;
                transform: translate3d(0, 0px, 0);
            }
        }
    }

    &.Active,
    &.Hover
    {
        &:after
        {
            @include overlay;
            display: block;
            border: 3px solid #aaa;
            pointer-events: none;
            z-index: 2147483647;
            mix-blend-mode: difference;
            content: "";
        }
    }

    &.Gradient
    {
        color: $color-white;
        background-image: $gradient-2;
    }

    &.Grayscale > .WidgetBackground
    {
        @include grayscale;
    }

    &.HasBackground
    {
        background-image: none;
    }

    &.HasBackgroundOverlay
    {
        .WidgetBackground
        {
            @include content-background-overlay;
        }
    }

    &.Orange
    {
        color: $color-white;
        background-color: $color-orange;
    }

    &.Pink
    {
        color: $color-white;
        background-color: $color-pink;
    }

    &.Purple
    {
        color: $color-white;
        background-color: $color-purple;
    }

    &.White
    {
        color: $color-black;
        background-color: $color-gray-light;
    }
}

.WidgetBackground
{
    @include content-background;
}

.WidgetDynamicAlign
{
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    bottom: auto;
    left: 20px;
    text-align: center;
    transform: translateY(-50%);

    .Widget.AlignBottom &
    {
        top: auto;
        bottom: 20px;
        transform: none;
    }

    .Widget.AlignLeft &
    {
        text-align: left;
    }

    .Widget.AlignRight &
    {
        text-align: right;
    }

    .Widget.AlignTop &
    {
        top: 20px;
        bottom: auto;
        transform: none;
    }
}

.WidgetEmpty
{
    @include center-text;
    @include no-select;
}

.WidgetToolbar
{
    $s: 24px;
    position: relative;
    opacity: 0;
    transform: translate3d( 0, 20px, 0 );
    transition: all $duration-quick $timing-in-out;
    overflow: hidden;

    .Icon
    {
        width: $s !important;
        height: $s !important;
    }

    .IconButton
    {
        margin: 0 5px;
    }

    @include hover
    {
        opacity: 1 !important;
    }
}

.WidgetToolbarButton
{
    @include button-behaviour;
    display: inline-block;
    position: relative;
    width: 44px;
    height: 44px;
    background-size: cover;
    vertical-align: top;
    cursor: pointer;
}

.WidgetToolbarContent
{
    top: -1px;
}

.WidgetToolbarWrapper
{
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0;
    z-index: 100000;

    .WidgetToolbar
    {
        position: relative;
        padding: 10px 7px;
        color: $color-black;
        background-color: $color-white;
        line-height: 24px;
    }

    &.AlignRight
    {
        right: 10px;
        left: auto;
    }

    &.Invert
    {
        .WidgetToolbar
        {
            color: $color-white;
            background-color: $color-black;
        }
    }
}