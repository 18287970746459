
@import '../../../Import/SCSS/classes';

.FileUpload {

    position: relative;
    padding: 20px 0;
    text-align: center;
    transition: all $duration / 5 ease;

}

.FileUploadAbort {

    @include button;

    margin: 20px 0 0;

}

.FileUploadContainer {

    @include no-select;
    
    position: relative;

    input {

        display: none;

    }

    &.Over {

        * {

            pointer-events: none;

        }

        .FileUploadOverlay {

            @include fade-up;

            z-index: 1000;

        }

    }

    &.Over,
    &.Uploading {

        .FileUpload {

            opacity: .25;
            transform: scale(0.95);

        }

    }

}

.FileUploadDescription {

    @include notice-small;

    margin-bottom: 0;

}

.FileUploadInstruction {

    max-width: 250px;
    margin: 0 auto;

}

.FileUploadOr {

    @include notice-small;

}

.FileUploadOverlay {

    @include overlay;

    background-color: color-gray( 0.8 );
    opacity: 0;

    .Icon {

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% );
        pointer-events: none;

    }

}

.FileUploadProgress {

    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY( -50% );
    text-align: center;

}

.FileUploadProgressOverlay {

    @include overlay;

    background-color: color-gray( 0.8 );
    z-index: 1000;

}

.FileUploadProgressInfo {

    @include notice-small;
    @include text-overflow;

    margin: 0 0 10px;

}