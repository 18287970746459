
@import '../../../Import/SCSS/classes';

$grid-margin: 10px;
$grid-handle: 5px;

.Grid {

    position: relative;
    padding: $grid-margin / 2;
    overflow: hidden;

    &.Interacting
    {
        .GridItem
        {
            pointer-events: none;
        }
    }

    &.Interactive {

        @include no-select;

        .GridItem {

            cursor: move;

            &.Active,
            &.Interacting {

                .GridItemResize {

                    opacity: 1;
                    transform: scale( 1 );
                    pointer-events: all;

                }

            }

            &.Interacted {

                z-index: 10;

            }

            &.InteractingMove {

                transition: height $duration-quick $timing-in-out;

                .GridItemWrapper {

                    box-shadow: $box-shadow;

                }

            }

            &.InteractingResize {

                transition: none;

            }

            &.NewItem {

                .GridItemWrapper {

                    @include fade-up;
                    
                }

            }

        }

        .GridItemContent {

            pointer-events: none;

        }

    }

    &.NotInteracting {

        .GridItem {

            @include hover {

                .GridItemResize {

                    opacity: 1;
                    transform: scale( 1 );
                    pointer-events: all;

                }

            }

        }

    }

}

.GridEmpty {

    @include notice-text;

    padding: 5px;
    color: $color-black;
    opacity: 0.5;

}

.GridItem {

    position: absolute;
    top: 0;
    left: 0;
    transition: all $duration-quick $timing-in-out;

}

.GridItemContent {

    @include overlay;

}

.GridItemResize {

    @include overlay;

    opacity: 0;
    transform: scale( 1.03 );
    transition: all $duration-quick $timing-in-out;
    pointer-events: none;
    z-index: 10;

}

.GridItemResizeHandle {

    position: absolute;
    width: $grid-handle;
    height: $grid-handle;
    background-color: currentColor;

    &.Bottom {

        bottom: 0;

    }

    &.Bottom.Center,
    &.Top.Center {

        cursor: ns-resize;

    }

    &.Bottom.Left,
    &.Top.Right {

        cursor: nesw-resize;

    }

    &.Bottom.Right,
    &.Top.Left {

        cursor: nwse-resize;

    }

    &.Center {

        left: calc( 50% - #{$grid-handle} / 2 );

    }

    &.Left {

        left: 0;

    }

    &.Middle {

        top: calc( 50% - #{$grid-handle} / 2 );
        cursor: ew-resize;

    }

    &.Right {

        right: 0;

    }

    &.Top {

        top: 0;

    }

}

.GridItems {

    position: relative;
    transition: all $duration-quick $timing-in-out;

}

.GridItemWrapper {

    @include overlay( $grid-margin / 2 );

    transition: all $duration-quick $timing-in-out;

}

.GridRowHandle
{

    position: absolute;
    right: 0;
    left: 0;
    height: 10px;
    margin-top: -5px;
    cursor: ns-resize;
    transition: all $duration-quick $timing-in-out;
    z-index: 11;
    //overflow: hidden;

    &:before {

        position: absolute;
        top: 4px;
        right: 5px;
        left: 5px;
        opacity: 0.5;
        border-bottom: 2px dashed currentColor;
        transition: opacity $duration-quick $timing-in-out;
        content: "";

    }

    &.Hide {

        pointer-events: none;

        &:before {

            opacity: 0 !important;

        }

    }

    &.Highlight {

        transition: none;

        .GridRowHandleLabel {

            opacity: 1;

        }

        &:before {

            opacity: 1;

        }

    }

    @include hover {

        .GridRowHandleLabel {

            opacity: 1;

        }

        &:before {

            opacity: 1;

        }

    }

}

.GridRowHandleLabel {

    position : absolute;
    top: 5px;
    left: 0;
    padding: 0.1em 0.2em;
    background-color: currentColor;
    font-size: 0.7em;
    font-weight: $font-weight-bold;
    line-height: normal;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity $duration-quick $timing-in-out;

    span {

        position: relative;
        color: $color-white;

    }

    &:before {

        position: absolute;
        top: 50%;
        right: 0;
        width: 1em;
        height: 1em;
        background-color: currentColor;
        transform: translate(50%, -50%) rotate(45deg);
        content: "";

    }

}

.GridWrapper {

    position: relative;

}