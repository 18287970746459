
@import '../../../../Import/SCSS/classes';

.ChoiceField {

    @include field;
    @include no-select;

    position: relative;
    cursor: pointer;

    label {

        @include field-label;

    }

    .Input {

        @include field-input;

        height: auto;
        color: $color-black;

    }

    .Option {

        @include item;
        @include item-interactive;

        padding-left: 0 !important;
        padding-right: 0 !important;
        color: $color-black !important;

        &.Selected {

            font-weight: 600;

        } 

    }

}