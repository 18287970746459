@import '../../../Import/SCSS/classes';

$block-s: 80px;
$block-r: 10px;

.ContentBlocks
{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: stretch;

    &.Disabled
    {
        .ContentBlocksTemplate
        {
            opacity: 0.5 !important;
            pointer-events: none !important;
        }

        .ContentBlockToolbar
        {
            opacity: 0 !important;
            pointer-events: none !important;
        }
    }

    &.FullWidth .ContentBlocksCanvas
    {
        max-width: none;
    }

    &.MultiColumn
    {
        .ContentBlock,
        .ContentBlockWrapper,
        .cb-bg
        {
            position: relative;
            height: 100%;
            box-sizing: border-box;
        }

        .ContentBlocksCanvas
        {
            display: flex;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: stretch;
        }
    }

    &.IsDragging
    {
        .ContentBlocksCanvas
        {
            @include no-select;
        }

        .ContentBlockToolbar
        {
            opacity: 0 !important;
            pointer-events: none;
        }

        .IsDragged .ContentBlockToolbar
        {
            opacity: 1 !important;
        }
    }

    &.OnCanvas.HasNoBlocks .ContentBlocksCanvas
    {
        background-color: $color-gray;
    }
}

.ContentBlocksCanvas
{
    max-width: 938px;
    margin: 0 auto 40px;
    background-color: $color-white;
    border: 1px solid #d1d1d1;
}

.ContentBlocksCanvasWrapper
{
    position: relative;
    flex-grow: 1;
    padding: 0 0 0 20px;
    margin: 0 0 0 20px;
    border-left: 1px solid $color-gray;
}

.ContentBlockContainer
{
    @include fade-up;

    position: relative;

    &.HasIndicator
    {
        z-index: 2 !important;
    }

    &.IsDragged
    {
        animation: none !important;
        //background-color: $color-gray-light;
        z-index: 3 !important;

        &:before
        {
            @include overlay;

            display: block;
            pointer-events: none;
            border: 2px dashed color-black(0.1);
            background-color: color-black(0.025);
            content: "";
            z-index: 0;
        }

        .ContentBlockWrapper
        {
            background-color: $color-white;
            outline: 1px solid #d1d1d1;
            opacity: 0.75;
            box-shadow: $box-shadow;
        }
    }

    &.IsPassive
    {
        pointer-events: none;
    }

    &.IsSelected
    {
        z-index: 1;
        
        .ContentBlockWrapper:after
        {
            @include overlay;

            display: block;
            content: "";
            pointer-events: none;
            border: 2px dashed $color-gray;
        }
    }
}

.ContentBlockDebug
{
    position: absolute;
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    z-index: 2;
    padding: 3px;
    color: $color-white;
    background-color: $color-purple;
    font-size: 8px;
    font-weight: $font-weight-bold;
    line-height: 10px;
    text-align: center;
}

.ContentBlockWrapper
{
    position: relative;
}

.ContentBlockInsert
{
    position: relative;
    width: 100%;
    height: 0;
    z-index: 2;

    .ContentBlockInsertLabel
    {
        position: absolute;
        top: 1px;
        left: 50%;
        padding: 4px 5px 5px;
        color: $color-white;
        background-color: $color-purple;
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        transform: translate(-50%, -50%);
        border-radius: 3px;
    }

    .ContentBlockInsertLine
    {
        position: absolute;
        top: -1px;
        right: 0;
        left: 0;
        border-bottom: 2px dashed $color-purple;
    }
}

.ContentBlockInsertVertical
{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    z-index: 1;

    &.Before
    {
        left: 0;

        .ContentBlockInsertLabel
        {
            transform: translate(-25%, -50%) rotate(90deg);
        }

        .ContentBlockInsertLine
        {
            border-right: 2px dashed $color-purple;
        }
    }

    &.After
    {
        right: 0;

        .ContentBlockInsertLabel
        {
            transform: translate(-75%, -50%) rotate(-90deg);
        }

        .ContentBlockInsertLine
        {
            border-left: 2px dashed $color-purple;
        }
    }

    .ContentBlockInsertLabel
    {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 4px 5px 5px;
        color: $color-white;
        background-color: $color-purple;
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        border-radius: 3px;
    }

    .ContentBlockInsertLine
    {
        position: absolute;
        top: 0;
        bottom: 0;
    }
}

.ContentBlockToolbar
{
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 2px 3px 3px;
    color: $color-white;
    background-color: $color-purple;
    line-height: 1;
    border-radius: 3px;
    opacity: 0;
    z-index: 100;

    .ContentBlockWrapper:hover > &
    {
        opacity: 1;
    }
}


.ContentBlocksEmpty
{
    @include heading-2;
    @include no-select;

    display: flex;
    width: 100%;
    min-height: 100px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .Icon
    {
        width: 1.25em !important;
        height: 1.25em !important;
        margin-right: 0.25em;
    }
}

.ContentBlocksTemplate
{
    @include no-select;

    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $block-s;
    height: $block-s;
    color: $color-white;
    background-color: $color-purple;
    border-radius: $block-r;
    overflow: hidden;
    animation: bounceIn 1s ease-in-out both;
    transition: opacity $duration-quick $timing-in-out;
    cursor: pointer;

    &[data-dragged="1"]
    {
        animation: none;
        opacity: 0.5;
        pointer-events: none;
    }
}

.ContentBlocksTemplateIcon
{
    width: 2em !important;
    height: 2em !important;
    margin-top: 0.25em;
}

.ContentBlocksTemplateLabel
{
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ContentBlocksTemplateWrapper
{
    position: relative;
    width: $block-s;
    height: $block-s;
    margin: $block-r / 2;
    background-color: color-black(0.1);
    border-radius: $block-r;
}

.ContentBlocksTemplates
{
    display: flex;
    position: relative;
    padding: 0 0 20px;
    flex-direction: column;
    margin: $block-r / -2;
    z-index: 10;
}

@include keyframes(bounceIn)
{
    0%
    {
        transform: scale(0);
    }

    20%
    {
        transform: scale(1.1);
    }

    40%
    {
        transform: scale(0.9);
    }

    60%
    {
        transform: scale(1.05);
    }

    80%
    {
        transform: scale(0.975);
    }

    100%
    {
        transform: scale(1);
    }
}