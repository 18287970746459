@import "../../../../../Import/SCSS/classes";

.WidgetConversationButton
{
    display: block;
    padding: 0 0 10px;
    text-align: center;
}

.WidgetConversationComments
{
    position: relative;
    padding: 5px 0;
}