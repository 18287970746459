@import "../../../../../Import/SCSS/classes";

.WidgetTabsLabels
{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: $h-field;
    padding-left: 5px;
    line-height: $h-field;
    z-index: 1;
}

.WidgetTabsLabel
{
    @include button-behaviour;
    @include heading-3;

    &:after
    {
        display: none;
    }

    &.Active:after
    {
        display: block;
    }

    & + .WidgetTabsLabel
    {
        margin-left: 10px;
    }
}

.WidgetTabsTab
{
    display: none;

    &.Active
    {
        display: block;
    }
}

.WidgetTabsTabs
{
    position: absolute;
    top: $h-field;
    right: 0;
    bottom: 0;
    left: 0;
}