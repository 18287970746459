
@import "../../../Import/SCSS/classes";

.NotFound {

    @include fade-up;
    @include overlay;

    padding: 20px;
    background: $gradient-1;

}