@import '../../../Import/SCSS/classes';

.ContentListItem
{
    @include list-item;
    @include list-item-interactive;

    text-align: left;

    &.ErrorItem
    {
        opacity: 0.5 !important;
        pointer-events: none; 
    }
}

.ContentListItemTray
{
    @include fade-up;

    position: relative;
    font-size: 12px;
    margin-top: 5px;
    white-space: nowrap;
    pointer-events: none; 
}

.ContentListItemTrayCounter,
.ContentListItemTrayItem
{
    display: inline-block;
    position: relative;
    vertical-align: middle;

    &:first-child
    {
        margin-left: 0;
    }

    &:last-child
    {
        margin-right: 0;
    }
}

.ContentListItemTrayCounter
{
    top: -1px;
    margin: 0 30px 0 2px;
}

.ContentListItemTrayItem
{
    margin: 0 2px 0 5px;
}