$color-black:               #000000; @function color-black($opacity: 1) { @return rgba(0, 0, 0, $opacity) };
$color-gray:                #e6e6e6; @function color-gray($opacity: 1) { @return rgba(230, 230, 230, $opacity) };
$color-gray-light:          #f4f4f4; @function color-gray-light($opacity: 1) { @return rgba(244, 244, 244, $opacity) };
$color-green:               #7eec34; @function color-green($opacity: 1) { @return rgba(126, 236, 52, $opacity) };
$color-orange:              #ee7402; @function color-orange($opacity: 1) { @return rgba(238, 116, 2, $opacity) };
$color-orange-light:        #f17d33; @function color-orange-light($opacity: 1) { @return rgba(241, 125, 51, $opacity) };
$color-pink:                #eb0c8e; @function color-pink($opacity: 1) { @return rgba(235, 12, 142, $opacity) };
$color-purple:              #78153a; @function color-purple($opacity: 1) { @return rgba(120, 21, 58, $opacity) };
$color-red:                 #ff3710; @function color-red($opacity: 1) { @return rgba(255, 55, 16, $opacity) };
$color-white:               #ffffff; @function color-white($opacity: 1) { @return rgba(255, 255, 255, $opacity) };

$gradient-1:                linear-gradient(25deg, color-white(.1), color-white(0));
$gradient-2:                linear-gradient(135deg, $color-orange-light, $color-pink);
$gradient-3:                linear-gradient(180deg, color-white(1), color-white(.85));

$font-family:               'Lato', sans-serif;
$font-family-headline:      'Ubuntu', sans-serif;
$font-size:                 16px;
$font-weight:               300;
$font-weight-bold:          700;
$font-weight-headline:      400;
$line-height:               22px;

$h-field:                   40px;
$w-content:                 1200px;

$box-shadow:                0 3px 5px color-black(0.1);
$box-shadow-strong:         0 3px 5px color-black(0.5);
$box-shadow-small:          0 2px 3px color-black(0.5);

$duration:                  1s;
$duration-quick:            .3s;
$timing-in:                 ease-in;
$timing-out:                ease-out;
$timing-in-out:             ease;