@import "../../../Import/SCSS/classes";

.ContentItem
{
    @include button-behaviour(1.03);

    display: block;
    position: relative;

    &.ErrorItem
    {
        @include button-disabled;
        background-image: $gradient-2;
    }

    &.Grayscale > .ContentItemBackground
    {
        @include grayscale;
    }

    &.HasShadow
    {
        box-shadow: $box-shadow-strong;
    }

    &.LabelBelow
    {
        .ContentItemLabel
        {
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            padding-top: 15px;
            white-space: normal;
            text-align: left;
        }
    }

    &.LabelOnTop
    {
        overflow: hidden;

        .ContentItemLabel
        {
            @include heading-2;

            margin: 10px 0;
            text-align: center;
        }
    }

    &.HasText
    {
        @include hover
        {
            .ContentItemLabel
            {
                opacity: 0.25;
            }

            .ContentItemText
            {
                opacity: 1;
            }

            .ContentItemTextWrapper
            {
                transform: translate3d(0,0,0);
            }
        }
    }
}

.ContentItemBackground
{
    @include content-background;
    @include content-background-overlay;
}

.ContentItemChildren
{
    position: absolute;
    right: 10px;
    bottom: 10px;
    left: 10px;
    text-align: center;
    transition: opacity $duration-quick $timing-in-out;
}

.ContentItemTag
{
    position: absolute;
    top: 20px;
    right: 0;
    padding: 5px 15px 5px 10px;
    font-size: 0.8em;
    font-weight: $font-weight-bold;
}

.ContentItemText,
.ContentItemTextWrapper
{
    transition: all $duration-quick $timing-in-out;
}

.ContentItemText
{
    @include overlay;

    opacity: 0;
    background-color: color-black(0.5);
    overflow: auto;

    &::-webkit-scrollbar
    {
        display: none;
    }
}

.ContentItemTextWrapper
{
    position: relative;
    padding: 20px;
    font-weight: 400;
    text-align: left;
    white-space: normal;
    transform: translate3d(0,20px,0);
    overflow-y: auto;
    overflow-x: hidden;
}