@import "../../../../../Import/SCSS/classes";

.ViewArticlesPreview
{
    position: relative;
}

.ViewArticlesPreviewButtons
{
    display: flex;
    align-items: center;

    .ButtonContainer
    {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.ViewArticlesPreviewCode
{
    max-width: 938px;
    max-height: calc(100vh - 400px);
    margin: 0 auto;
    border: 1px solid #d1d1d1;
    overflow: auto;
}

.ViewArticlesPreviewFrame
{
    display: block;
    width: 100%;
    height: calc(100vh - 400px);
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
}

.ViewArticlesPreviewMessage
{
    @include notice-small;

    margin-left: 20px;
}

.ViewArticlesPreviewMinify
{
    @include no-select;

    display: flex;
    flex-direction: column;
    margin-left: auto;
    text-align: right;

    .CheckboxField
    {
        margin: 0;
    }

    .CheckboxFieldContent
    {
        padding: 0;
        font-weight: 300;

        span
        {
            opacity: 0.5;
        }
    }
}

.ViewArticlesPreviewTab
{
    position: relative;
    padding: 10px 0;

    .CodeFieldWrapper
    {
        max-height: none;
    }
}

.ViewArticlesPreviewTabMenu
{
    
}