
@import '../../../../Import/SCSS/classes';

.WidgetsField {

    @include field;

    position: relative;

    label {

        @include field-label;

    }

    &.Disabled {

        .WidgetsFieldGrid {

            opacity: 0.5;
            pointer-events: none;

        }

    }

}

.WidgetsFieldIcon {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

}

.WidgetsFieldGrid {

    margin-bottom: 10px;
    background-color: color-black( 0.1 );

    .GridItem {

        color: $color-white;

    }

}

.WidgetsFieldTray {

    position: relative;

    .ButtonContainer {

        margin: 0;

    }

}

.WidgetsFieldWidget {

    @include overlay;

    background-color: $color-purple;
    overflow: hidden;

}