@import "../../../../../Import/SCSS/classes";

.WidgetWysiwygContent
{
    @include content;
}

.WidgetWysiwygContentWrapper
{
    padding: 20px;
}