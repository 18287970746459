@import '../../../../Import/SCSS/classes';

.FeaterIconFieldOption
{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    svg
    {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        stroke-width: 1;
    }
}