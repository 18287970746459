@import "../../../../../Import/SCSS/classes";

.WidgetCommunitiesContent
{
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    h2
    {
        margin: 0 !important;
        padding: 0 0 5px 40px;
    }

    .SliderWrapper
    {
        text-align: left;
    }
}

.WidgetCommunitiesEmpty
{
    @include fade-up;

    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: -2em;
    font-size: 1.25em;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    z-index: 1000;
}

.WidgetCommunitiesError
{
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: -2.5em;
    color: currentColor;
    font-size: 1.25em;
    line-height: 1.2;
    text-align: center;
    z-index: 1000;
}

.WidgetCommunitiesItem
{
    margin: 0 10px;

    &:first-child
    {
        margin-left: 40px;
    }
}

.WidgetCommunitiesItems
{
    padding: 10px 0;
    text-align: left;
}