@import '../../../Import/SCSS/classes';

.TabMenu
{
    @include no-select;

    white-space: nowrap;

    &.Horisontal
    {
        position: relative;

        .TabMenuItem
        {
            @include item-small;

            &.Selected
            {
                font-weight: $font-weight-bold;
            }
        }
    }

    &.Vertical
    {
        position: absolute;
        top: 20px;
        left: 0;
        transform: rotate( 90deg );
        transform-origin: top left;
    }
}

.TabMenuItem
{
    @include button-transition;

    display: inline-block;
    position: relative;
    margin: 0 10px;
    vertical-align: top;
    cursor: pointer;

    &:first-child
    {
        margin-left: 0;
    }

    &:last-child
    {
        margin-right: 0;
    }

    &.Disabled
    {
        opacity: .25;
        pointer-events: none;
    }
}