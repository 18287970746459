@import "../../../../../Import/SCSS/classes";

.WidgetAssignedLearningButton
{
    display: block;
    padding: 0 0 10px;
    text-align: center;
}

.WidgetAssignedLearningItem
{
    @include list-item;
    @include list-item-interactive;
}

.WidgetAssignedLearningItems
{
    @include list-items;
}