
@import "../../../../../Import/SCSS/classes";

.WidgetContentAlign
{
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
}

.WidgetContentItem
{
    margin: 0 10px;
}

.WidgetContentItems
{
    padding: 10px 0;
    text-align: left;
}

.WidgetContentList
{
    @include list-items;
}