@import "../../../../../Import/SCSS/classes";

.WidgetEventsFilter
{
    .FilterFieldInput,
    .FilterFieldLabel
    {
        color: inherit !important;
        text-align: right !important;
    }

    .FilterFieldLabel
    {
        margin-right: 35px;
    }

    .Icon
    {
        color: inherit !important;
    }
}

.WidgetEventsItem
{
    @include list-item;
    @include list-item-interactive;
}

.WidgetEventsItems
{
    @include list-items;
}