@import "../../../../../Import/SCSS/classes";

.WidgetSuccessFactorsAlign
{
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
}

.WidgetSuccessFactorsClose
{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
}

.WidgetSuccessFactorsExpand
{
    @include overlay;
    @include fade-up;
    
    z-index: 5000;
    overflow: auto;
    background-image: $gradient-3;

    .CollapsableContent
    {
        overflow: hidden;
    }

    &.HasOverflow
    {
        .WidgetSuccessFactorsExpandText
        {
            mask-image: linear-gradient(0deg, color-black(0) 0%, color-black(1) 50%);
        }

        &.ShowOverflow .WidgetSuccessFactorsExpandText
        {
            mask-image: linear-gradient(0deg, color-black(0) 0%, color-black(1) 0%);
        }
    }
}

.WidgetSuccessFactorsExpandContent
{
    position: relative;
    padding: 60px 40px 40px;

    p
    {
        position: relative;
        max-width: 500px;
        margin: $line-height / 2 0;

        a
        {
            color: $color-purple;
            text-decoration: underline;
        }
    }
}

.WidgetSuccessFactorsExpandNoText
{
    font-style: italic;
}

.WidgetSuccessFactorsItem
{
    @include button-behaviour(1.03);

    position: relative;
    width: 314px;
    height: 200px;
    color: $color-white;
    background-color: $color-black;
    box-shadow: $box-shadow-strong;
    overflow: hidden;
}

.WidgetSuccessFactorsItemBackground
{
    @include overlay;

    background-size: cover;

    &.Error
    {
        opacity: 1 !important;
        background-image: $gradient-2 !important;
    }

    &.Loaded
    {
        @include content-background-overlay;
    }
}

.WidgetSuccessFactorsItemLabel
{
    @include heading-2;

    position: absolute;
    right: 10px;
    bottom: 20px;
    left: 10px;
    text-align: center;
    transition: opacity $duration-quick $timing-in-out;
}

.WidgetSuccessFactorsItems
{
    padding: 10px 0;
    
    .SliderWrapper
    {
        text-align: left;
    }
}

.WidgetSuccessFactorsItemsList
{
    @include list-items;      
}

.WidgetSuccessFactorsItemWrapper
{
    position: relative;
    margin: 0 10px;
}

.WidgetSuccessFactorsListItem
{
    @include list-item;
    @include list-item-interactive;
}