
@import "../../../../../Import/SCSS/classes";

.WidgetFavouritesButton
{
    display: block;
    padding: 0 0 10px;
    text-align: center;
}

.WidgetFavouritesFilter
{
    span,
    .Icon,
    .Input
    {
        color: inherit !important;
        background-color: transparent;
    }
}

.WidgetFavouritesItem
{
    @include list-item;
    @include list-item-interactive;
}

.WidgetFavouritesItems
{
    @include list-items;
}