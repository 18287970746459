
@import "vars";
@import "mixins";

@mixin button($c: $color-purple, $t: $color-white)
{
    $p: 18px;

    @include button-behaviour;
    @include field-input-font;

    display: inline-block;
    position: relative;
    padding: 0 $p;
    color: $t;
    background-color: $c;
    border: 1px solid $c;
    font-weight: 600;
    text-align: center;
    line-height: $h-field - 2px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
}

@mixin button-behaviour($s: 1.1)
{
    @include no-select;
    @include button-transition;

    cursor: pointer;
    outline: none;

    @include hover
    {
        @include button-hover($s);
    }

    @include active
    {
        transform: scale(1) !important;
    }
}

@mixin button-disabled
{
    opacity: .5 !important;
    transform: scale(1) !important;
}

@mixin button-hover($s: 1.1)
{
    transform: scale($s);
}

@mixin button-transition($d: $duration-quick)
{
    transition: transform $d ease, opacity $d ease;
}

@mixin center-text
{
    @include heading-2;

    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    text-align: center;
    transform: translateY(-50%);
}

@mixin content
{
    b, strong
    {
        font-weight: 600;
    }

    em, i
    {
        font-style: italic;
    }

    u
    {
        text-decoration: underline;
    }
}

@mixin content-background
{
    @include overlay;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

@mixin content-background-overlay($from: .5, $to: .1)
{
    &:after
    {
        @include overlay;

        content: "";
        background: linear-gradient(0deg, color-black($from), color-black($to));
    }

    &.NoOverlay:after
    {
        display: none;
    }
}

@mixin dialog
{
    @include fade-up;

    position: relative;
    padding: 10px;
    color: $color-black;
    background-color: $color-gray;
    text-align: left;
}

@mixin dialog-close
{
    position: absolute !important;
    top: 10px;
    right: 10px;
}

@mixin dialog-title
{
    @include heading-3;
}

@mixin fade-down($d: $duration)
{
    animation: fadeDown #{$d} $timing-in-out both;
}

@mixin fade-in($d: $duration)
{
    animation: fadeIn #{$d} $timing-in-out both;
}

@mixin fade-left($d: $duration)
{
    animation: fadeLeft #{$d} $timing-in-out both;
}

@mixin fade-out($d: $duration)
{
    animation: fadeOut #{$d} $timing-in-out both;
}

@mixin fade-right($d: $duration)
{
    animation: fadeRight #{$d} $timing-in-out both;
}

@mixin fade-up($d: $duration)
{
    animation: fadeUp #{$d} $timing-in-out both;
}

@mixin field
{
    margin: 10px 0;

    .StickyAlign
    {
        left: auto;
        right: 12px;
    }

    &:first-child
    {
        margin-top: 0;
    }

    &.Darken .Input
    {
        background-color: $color-gray;
    }
}

@mixin field-box($s: 19px, $sh: 1px 1px 0 color-black(0.1))
{
    display: inline-block;
    position: relative;
    top: -1px;
    width: $s;
    height: $s;
    margin-right: 10px;
    vertical-align: middle;
    box-shadow: $sh;
}

@mixin field-focus($c: color-black(1))
{
    .Icon
    {
        color: $c;
    }

    .Input
    {
        color: $c;

        @include input-placeholder
        {
            color: $c;
            opacity: 0;
        }
    }
}

@mixin field-icon($h: $h-field)
{
    $p: 10px;

    display: block !important;
    position: absolute !important;
    right: $p;
    bottom: $h / 2 - 9px;
    color: color-black(0.5);
}

@mixin field-input($h: $h-field, $f: 0.875em, $l: 1.36)
{
    $p: 10px;

    @include vendor-prefix(appearance, none);
    @include field-input-font($f, $l);

    display: block;
    position: relative;
    width: calc(100% - #{$p});
    height: $h;
    min-width: 150px;
    padding: 0 0 0 $p;
    color: color-black(0.5);
    border: none;
    background-color: $color-white;

    @include input-placeholder
    {
        color: color-black(0.25);
        transition: opacity $duration-quick $timing-in-out;
    }

    &:focus
    {
        outline: none;
    }

    &:-webkit-autofill
    {
        -webkit-text-fill-color: $color-white;
        -webkit-box-shadow: 0 0 0px 1000px $color-black inset;
    }
}

@mixin field-input-font($f: 0.875em, $l: 1.36)
{
    font-size: $f;
    font-weight: 400;
    text-align: left;
    line-height: $l;
}

@mixin field-label
{
    @include no-select;

    display: block;
    position: relative;
    font-size: 0.75em;
    font-weight: $font-weight-bold;
    line-height: 2;
    text-align: left;

    .IconButton
    {
        top: -0.1em;
        width: 1em;
        height: 1em;
        margin-left: 0.25em;
        opacity: 0;

        .Icon
        {
            width: 100% !important;
            height: 100% !important;
        }
    }

    @include hover
    {
        .IconButton
        {
            opacity: 1;
        }
    }
}

@mixin heading($f: 2em, $l: $f, $m: 15px)
{
    position: relative;
    margin: $m 0;
    font-family: $font-family-headline;
    font-size: $f;
    font-weight: $font-weight-headline !important;
    line-height: $l;
    white-space: normal;

    &:first-child
    {
        margin-top: 0;
    }

    &:last-child
    {
        margin-bottom: 0;
    }
}

@mixin heading-1
{
    @include heading;
}

@mixin heading-2
{
    @include heading(1.5em, 1.167);
}

@mixin heading-3
{
    @include heading(0.875em);
    @include no-select;
    
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:after
    {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: $color-pink;
        content: "";
    }

    & + *
    {
        margin-top: 0 !important;
    }
}

@mixin item($f: 0.875em, $l: 1.36)
{

    @include field-input-font($f, $l);
    @include no-select;
    @include text-overflow;

    position: relative;
    min-height: $l;
    padding: 5px 10px;

    &:first-child
    {
        padding-top: 10px;
    }

    &:last-child
    {
        padding-bottom: 10px;
    }
}

@mixin item-small
{
    font-size: 0.75em;
    text-transform: uppercase;
}

@mixin item-interactive
{
    cursor: pointer;

    color: color-black(0.5);

    @include hover
    {
        color: color-black( 1 );
    }
}

@mixin list-item
{
    display: block;
    position: relative;
    padding: 5px;
    animation: fadeUp $duration $timing-in-out both;

    .ItemContent
    {
        @include list-item-content;
    }

    .ItemInfo
    {
        @include text-overflow;
        opacity: .5;
    }

    .ItemName,
    .ItemTitle
    {
        @include list-item-title;
    }

    .ItemPreview
    {
        @include list-item-preview;
    }

    &:after
    {
        display: block;
        clear: both;
        content: "";
    }
}

@mixin list-item-content
{
    position: relative;
    padding: 8px 15px;
    overflow: hidden;
}

@mixin list-item-interactive
{
    cursor: pointer;

    @include hover
    {
        background-color: color-black(0.05);
    }

    &.Active
    {
        background-color: color-black(0.05);
    }
}

@mixin list-item-preview
{
    position: relative;
    float: left;
}

@mixin list-item-title
{
    @include text-overflow;

    position: relative;
    font-size: 1.125em;
    font-weight: $font-weight-bold;
}

@mixin list-items
{
    position: relative;
    padding: 5px 0;
}

@mixin notice
{
    @include notice-text;

    position: relative;
    margin: 10px 0;
    padding: 10px;
}

@mixin notice-small
{
    font-size: 0.875em;
    opacity: 0.5;
}

@mixin notice-text
{
    font-size: 0.875em;
    font-weight: 400;
    font-style: italic;
}