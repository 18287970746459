@import "../../../Import/SCSS/classes";

.LogBadge
{
    @include no-select;

    display: inline-block;
    position: relative;
    padding: 3px 6px 2px;
    color: $color-black;
    background-color: color-black(0.1);
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
    white-space: nowrap;
    border-radius: 2px;
    vertical-align: middle;
    cursor: pointer;

    &.LogBadgeContainer,
    &.LogBadgeContent
    {
        .Icon
        {
            width: 0 !important;
            margin-right: 0;
            opacity: 0;
            overflow: hidden;
        }
    }

    .Icon
    {
        position: relative;
        top: -0.1em;
        margin-right: 3px;
    }

    & + .LogBadge
    {
        margin-left: 3px;
    }

    @include hover
    {
        color: $color-white;
        background-color: $color-purple;
    }
}

.LogContent
{
    right: -10px;
    left: -10px;
}

.LogContentWrapper
{
    position: relative;
    height: 400px;
    margin: 10px 0;
}

.LogEntries
{
    @include list-items;

    padding: 0 0 0 10px;
}

.LogEntry
{
    @include list-item;

    b, strong, .LogEntryLink
    {
        font-weight: 500;
    }
}

.LogEntryAction
{
    position: relative;
    margin: 3px 0;
    font-size: 14px;
    line-height: 18px;
}

.LogEntryLink,
.LogEntryMoreFields
{
    text-decoration: underline;
}

.LogEntryTime
{
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    line-height: 14px;
    vertical-align: middle;
}

.LogError
{
    top: 50%;
    transform: translateY(-60%);
}

.LogMoreButton
{
    display: block;
    padding: 0 0 10px;
    text-align: center;
}

.LogTray
{
    .ButtonContainer
    {
        margin: 0 !important;   
    }
}