@import "../../../Import/SCSS/classes";

.Sticky
{
    $a: 8px;

    position: fixed;

    &.AlignRight
    {
        margin: $a * 1.5 0 0 $a * 1.5;
        transform: translateX(-100%);

        &.AlignTop
        {
            transform: translate(-100%, -100%);
        }

        &:before
        {
            right: $a * 2;
        }
    }

    &.AlignCenter
    {
        margin: $a * 1.5 0 0;
        transform: translateX(-50%);

        &.AlignTop
        {
            transform: translate(-50%, -100%);
        }

        &:before
        {
            left: 50%;
            margin-left: $a / -2;
        }
    }

    &.AlignLeft
    {
        margin: $a * 1.5 0 0 $a * -1.5;

        &.AlignTop
        {
            transform: translateY(-100%);
        }

        &:before
        {
            left: $a * 2;
        }
    }

    &.Flip
    {
        .StickyContent
        {
            bottom: 0;

            &:before
            {
                top: auto;
                bottom: $a / -2;
            }
        }
    }
}

.StickyAlign
{
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
}

.StickyBackdrop
{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4000;
}

.StickyContainer
{
    @include fade-up($duration-quick);

    position: relative;
    opacity: 0;
    z-index: 5000;
}

.StickyContent
{
    $a: 8px;

    position: relative;
    width: 100%;
    box-shadow: $box-shadow;

    &:before
    {
        display: block;
        position: absolute;
        top: $a / -2;
        right: 16px;
        width: $a;
        height: $a;
        background-color: $color-white;
        box-shadow: $box-shadow;
        transform: rotate(45deg);
        content: "";
    }
}

.StickyContentWrapper
{
    position: relative;
    color: $color-black;
    background-color: $color-white;
}