
@import '../../../Import/SCSS/classes';

.ImageDetailsForm {

    position: relative;
    padding: 10px 0;

}

.ImageDetailsPreview {

    position: relative;
    overflow: hidden;
    color: $color-white;
    background-color: $color-black;
    background-position: center center;
    box-shadow: $box-shadow-small;
    transition: all $duration $timing-in-out;

    &:after {

        @include overlay;

        border: 2px solid $color-white;
        content: "";

    }

}

.ImageDetailsPreviewImage {

    @include overlay;

    background-size: cover;

}

.ImageDetailsTraySecond {

    position: relative;
    white-space: nowrap;

    .IconItem {

        display: inline-block;
        position: relative;
        margin: 0 5px;
        vertical-align: top;

        &:first-child {

            margin-left: 0;
            
        }

        &:last-child {

            margin-right: 0;
            
        }
    
    
    }

}