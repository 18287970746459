
@import '../../../../Import/SCSS/classes';

.CodeField
{
    @include field;

    position: relative;

    label 
    {
        @include field-label;
    }

    .Input
    {
        background-color: $color-white;
    }
}

.CodeFieldWrapper
{
    max-height: 200px;
    overflow: auto;
}