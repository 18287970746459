
@import "../../../../../Import/SCSS/classes";

.WidgetButtonText
{
    @include heading-2;

    display: table-cell;
    vertical-align: middle;

    p
    {
        margin: 0;
    }

    .Widget.AlignTop &
    {
        vertical-align: top;
    }
}

.WidgetButtonTextContainer
{
    display: table;
    position: relative;
    width: 100%;
    min-height: (28px * 3);
}