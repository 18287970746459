
@import '../../../../Import/SCSS/classes';

.TextField
{
    @include field;

    position: relative;

    label
    {
        @include field-label;
    }

    .FieldIcon
    {
        @include field-icon;
    }

    .Input
    {
        @include field-input;
    }

    .InputWrapper
    {
        position: relative;
    }

    &.Big
    {
        .Input
        {
            @include field-input(50px, 22px, 26px);
        }
    }

    &.Disabled .Input
    {
        opacity: .5;
    }

    &.Error
    {
        @include field-focus($color-red);
    }

    &.Focus
    {
        @include field-focus;
    }

    &.ReadOnly
    {
        .Input
        {
            height: auto;
            min-height: $line-height + 1px;
        }
    }
}

.TextFieldSpinner
{
    @include field-icon;
}