@import '../../../Import/SCSS/classes';

.VideoPlayer
{
    @include overlay;

    color: $color-white;
    background-color: $color-black;
    overflow: hidden;

    video
    {
        @include overlay;
        width: 100%;
        height: 100%;
    }

    @include hover
    {
        .VideoPlayerControls
        {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.VideoPlayerControls
{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 8px;
    opacity: 0;
    transform: translateY(100%);
    transition: all $duration-quick $timing-in-out;
    background: linear-gradient(0deg, color-black(0.5), color-black(0));

    &:after
    {
        display: block;
        clear: both;
        content: "";
    }

    .IconButton,
    .SpinnerContainer
    {
        display: inline-block;
        width: 32px !important;
        height: 32px !important;
        margin: 4px;
        vertical-align: middle;

        .Icon,
        .Spinner
        {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.VideoPlayerControlsLeft,
.VideoPlayerControlsRight
{
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
}

.VideoPlayerControlsLeft
{
    float: left;
}

.VideoPlayerControlsRight
{
    float: right;
}

.VideoPlayerControlsSeek
{
    position: relative;
    padding: 14px;
    overflow: hidden;
}

.VideoPlayerPoster
{
    @include overlay;

    .LoadImage
    {
        @include content-background;
        @include content-background-overlay;
        background-color: $color-black;
        background-size: contain;
    }

    @include hover
    {
        .VideoPlayerPosterInfo
        {
            opacity: 1;
        }
    }
}

.VideoPlayerPosterContent
{
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
}

.VideoPlayerPosterInfo
{
    position: relative;
    margin-top: 0.5em;
    opacity: 0.5;
    transition: opacity $duration-quick ease;
}

.VideoPlayerPosterInfoCell
{
    display: inline-block;
    position: relative;
    margin: 0 0.5em;
    vertical-align: middle;

    .Icon
    {
        margin-right: 0.25em;
    }
}

.VideoPlayerPosterPlay
{
    position: relative;
    left: 8px;
    width: 80px !important;
    height: 80px !important;
    
    .Icon
    {
        width: 100% !important;
        height: 100% !important;
    }
}