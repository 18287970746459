
@import "../../../../../Import/SCSS/classes";

.WidgetUserActivities
{
    .WidgetError
    {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
    }
}

.WidgetUserActivitiesItem
{
    @include list-item;
}

.WidgetUserActivitiesItemAction,
.WidgetUserActivitiesItemSince
{
    font-size: 0.75em !important;
    line-height: 1.33 !important;
    opacity: 1 !important;
}

.WidgetUserActivitiesItems
{
    @include list-items;
}
