
@import '../../../Import/SCSS/classes';

$counter-duration: $duration-quick;

.Counter {

    @include no-select;

    position: relative;

    &.Decimals1 .CounterNumberContainer {

        transform: scale(0.75) translateY(-5%);

    }

    &.Decimals2 .CounterNumberContainer {

        transform: scale(0.5) translateY(-20%);

    }

}

.CounterContainer {

    position: relative;
    padding: 0 0 100%;

}

.CounterNumber {

    position: absolute;
    top: 0;
    left: 0;
    line-height: normal;

}

.CounterNumberContainer {

    position: relative;
    transition: all $counter-duration $timing-in-out;
    transform-origin: center bottom;

}

.CounterNumberCurrent {

    &.Down {

        animation: fadeDownIn $counter-duration $timing-in-out both;

    }

    &.Up {

        animation: fadeUpIn $counter-duration $timing-in-out both;

    }

}

.CounterNumberPrevious {

    &.Down {

        animation: fadeDownOut $counter-duration $timing-in-out both;

    }

    &.Up {

        animation: fadeUpOut $counter-duration $timing-in-out both;

    }

}

.CounterUnit {

    

}