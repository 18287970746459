@import '../../../../Import/SCSS/classes';

.ColorField
{
    @include field;
    @include no-select;
    position: relative;
    cursor: pointer;

    &.Disabled
    {
        .ColorFieldPicker
        {
            pointer-events: none;
        }

        .Input
        {
            opacity: .5;
        }
    }

    &.Error
    {
        @include field-focus($color-red);
    }

    &.Focus
    {
        @include field-focus;
    }

    label
    {
        @include field-label;
    }

    .IconItem
    {
        display: inline-block !important;
    }

    .Input
    {
        @include field-input;
        display: inline-block;
        width: calc(100% - #{$h-field} - 10px);
        box-sizing: border-box;
        vertical-align: top;
    }

    .NumberField
    {
        .InputWrapper
        {
            &:after
            {
                content: "deg";
            }

            .Input
            {
                display: inline-block;
                width: 45px;
                min-width: 0;
                padding: 0 10px 0 0;
                margin-right: 5px;
                text-align: right;
                vertical-align: middle;
            }
        }
    }
}

.ColorField1,
.ColorField2
{
    position: relative;

    .IconButton
    {
        @include field-icon;
    }
}

.ColorFieldGroupName
{
    @include item;
    @include field-label;

    padding-bottom: 0;

    .ColorFieldGroup:first-child &
    {
        padding-top: 0;
    }
}

.ColorFieldItem
{
    @include item;
    @include item-interactive;

    &.Selected
    {
        font-weight: 600;
    } 
}

.ColorFieldItems
{
    position: relative;
    max-height: 200px;
    overflow: auto;
}

.ColorFieldPicker
{
    @include button-behaviour;

    display: inline-block !important;
    position: relative;
    width: $h-field;
    height: $h-field;
    margin-right: 10px;
    vertical-align: top;
    box-shadow: 1px 1px 0 color-black(0.1);

    input
    {
        pointer-events: none;
        opacity: 0;
    }
}

.ColorFieldItemPreview
{
    @include field-box;
}