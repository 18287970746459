@import '../../../Import/SCSS/classes';

.User {

    @include button-behaviour( 1.03 );

    display: block;
    position: relative;
    text-align: center;

    &.HasAvatar .UserAvatar {

        background-color: transparent;

    }

}

.UserAvatar {

    display: inline-block;
    position: relative;
    color: $color-white;
    overflow: hidden;
    transition: background $duration $timing-in-out;

}

.UserAvatarIcon {

    @include fade-up;

    width: 100% !important;
    height: 100% !important;
    padding: 15%;
    box-sizing: border-box;

}

.UserAvatarImage {

    @include overlay;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

}