
@import '../../../Import/SCSS/classes';

.Overlay {

    position: fixed !important;
    padding: 20px;
    background: color-gray( 0.8 );
    z-index: 20000;

}

.OverlayDialog {

    @include dialog;

    opacity: 0;
    box-shadow: $box-shadow;

}

.OverlayDialogClose {

    @include dialog-close;

}

.OverlayDialogContent {

    text-align: left;
    
}

.OverlayDialogItem {

    position: relative;
    margin-top: 30px;
    color: $color-white;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    cursor: pointer;

    .OverlayDialogItem + & {

        margin-top: 5px;

    }

    @include hover {

        text-decoration: underline;

    }

    &.Disabled {

        text-decoration: none !important;
        opacity: .5;

    }

}

.OverlayDialogTitle {

    @include dialog-title;

}

.OverlayDialogSpinner {

    background-color: color-black( 0.5 );

}

.OverlayDialogTray {

    position: relative;
    margin-top: 30px;

}