
@import '../../../../Import/SCSS/classes';

.DateTimeField {

    @include field;

    label {

        @include field-label;

    }

}

.DateTimeFieldDateField {

    display: inline-block;
    position: relative;
    width: calc( 100% - 70px );
    margin: 0 10px 0 0;
    vertical-align: top;

}

.DateTimeFieldTimeField {

    display: inline-block;
    position: relative;
    width: 60px;
    margin: 0;
    vertical-align: top;

    .Input {

        width: 100%;
        min-width: 0;
        padding-left: 0;
        text-align: center;

    }

}