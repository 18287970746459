@import "../../../../../Import/SCSS/classes";

.WidgetImageAndTextIconWrapper
{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 40px;
    box-sizing: border-box;

    .IconPlay
    {
        left: 2px;
    }
}

.WidgetImageAndTextImage
{
    position: relative;
    width: 100%;
    aspect-ratio: 1.5;
    background-size: cover;
}