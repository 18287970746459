
@import '../../../Import/SCSS/classes';

.Insert {

    @include no-select;

    position: absolute;
    top: 7px;
    right: 12px;
    text-align: left;

    &.Expand {

        .InsertButton {

            z-index: 5000;

        }

    }

    .StickyAlign {

        left: 16px !important;
        right: auto !important;

    }

}

.InsertButton {

    opacity: .5;

    @include hover {

        opacity: 1;

    }

}

.InsertMenuItem {

    @include item;
    @include item-interactive;

}