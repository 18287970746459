
@import "../../../Import/SCSS/classes";

.Dialog
{
    .ItemBackground
    {
        background-color: $color-purple;
    }
}

.DialogConfirm
{
    text-align: center;
}

.DialogForm
{
    position: relative;
    margin: 10px 0 0;

    .Button
    {
        margin-top: 10px;
    }
}

.DialogMenu
{
    position: relative;
    margin: 30px 0;
    padding: 20px 10px;
    background-color: $color-gray-light;
}

.DialogMenuItemText
{
    @include notice-text;

    padding: 0 5px 0;
}

.DialogMessage
{
    margin: 10px 0 0;
    padding: 20px 0;
}