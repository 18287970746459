
@import '../../../Import/SCSS/vars';

.LoadImage {

    opacity: 0;
    transition: opacity $duration $timing-out;

    &.Loaded {

        opacity: 1;

    }

}