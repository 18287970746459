
@import "../../../../../Import/SCSS/classes";

.WidgetBlank
{
    @include no-select;
}

.WidgetBlankContentLabel
{
    @include heading-2;

    p
    {
        margin: 14px 0;

        &:first-child
        {
            margin-top: 0;
        }

        &:last-child
        {
            margin-bottom: 0;
        }
    }
}