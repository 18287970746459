
@import '../../../../Import/SCSS/classes';

.ConditionsField {

    @include field;

    position: relative;

    label {

        @include field-label;

    }

}

.ConditionsFieldAdd {

    display: inline-block;

}

.ConditionsFieldItem {

    position: relative;
    height: 40px;
    color: color-black( 0.5 );
    white-space: nowrap;

}

.ConditionsFieldItemField,
.ConditionsFieldItemOperator,
.ConditionsFieldItemType {

    display: inline-block;
    position: relative;
    vertical-align: top;
    box-sizing: border-box;

    .Field {

        margin: 0 !important;

    }

    .Input {

        min-width: 0 !important;
        font-style: italic;

    }

}

.ConditionsFieldItemField {

    width: calc( 48% - 18px );
    padding-right: 10px;

}

.ConditionsFieldItemOperator {

    width: 15%;

    .Input {

        width: 100% !important;

    }

}

.ConditionsFieldItemRemove {

    top: 8px;
    right: 10px;

    &:after {

        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5px;
        width: 1px;
        background-color: currentColor;
        opacity: 0.25;
        content: "";

    }

    :empty + &:after {

        opacity: 0;

    }

}

.ConditionsFieldItemType {

    width: 37%;

    .Input {

        width: 100% !important;

    }

}

.ConditionsFieldGroup {

    position: relative;

    .IconItem {

        padding: 0 10px 5px;

    }

    & + .ConditionsFieldGroup:before {

        display: block;
        position: relative;
        margin: 5px 15px;
        font-size: 12px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        content: "OR"

    }

}

.ConditionsFieldGroupContent {

    position: relative;
    color: $color-black;
    background-color: $color-white;

}