
@import '../../../Import/SCSS/classes';

.FormNotice {

    @include notice;

    color: $color-white;
    background-color: $color-purple;

}