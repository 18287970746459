
@import '../../../../Import/SCSS/classes';

.TextareaField {

    @include field;

    position: relative;

    label {

        @include field-label;

    }

    .Input {

        $p1: ( $h-field - $line-height ) / 2;
        $p2: 10px;

        @include field-input;

        width: calc( 100% - #{$p1} - #{$p2} );
        height: $line-height + 2px;
        padding: $p1 $p1 $p1 $p2;
        line-height: $line-height * .9;
        resize: none;

    }

    .InputWrapper {

        position: relative;

    }

    &.Disabled .Input {

        opacity: .5;

    }

    &.Error {

        @include field-focus( $color-red );

    }

    &.Focus {

        @include field-focus;

    }

    &.ReadOnly {

        .Input {

            height: auto;
            min-height: 20px;
            white-space: normal;

        }

    }

}