
@import '../../../Import/SCSS/classes';

.ImageGallery {

    .FileUpload {

        top: 50%;
        padding: 0;
        transform: translateY(-50%);

    }

    .FileUploadContainer {

        height: 100%;

    }

}

.ImageGalleryEmpty {

    @include center-text;

}

.ImageGalleryError {

    top: 50%;
    transform: translateY(-60%);

}

.ImageGalleryFilter {

    position: relative;
    padding: 10px 0;

    & > .Field {

        display: inline-block;
        width: 50%;
        margin: 0 !important;
        vertical-align: top;
        box-sizing: border-box;

        &:nth-child(even) {

            padding-left: 5px;

        }

        &:nth-child(odd) {

            padding-right: 5px;

        }

    }

}

.ImageGalleryItem {

    display: inline-block;
    width: 25%;

}

.ImageGalleryItems {

    top: 55px;
    margin: 5px -5px 10px;
    line-height: 0;
    text-align: center;

}

.ImageGalleryMore {

    text-align: center;

}

.ImageGalleryTab {

    @include overlay;

    opacity: 0;
    pointer-events: none;

    &.Active {

        @include fade-up;

        pointer-events: all;

    }

}

.ImageGalleryTabs {

    position: relative;
    height: 500px;

}

.ImageGalleryTray
{
    .ButtonContainer
    {
        margin: 0 0 10px !important; 
    }
}

.ImageGalleryTraySecond
{

    position: relative;
    white-space: nowrap;

    .IconItem
    {
        display: inline-block;
        position: relative;
        margin: 0 5px;
        vertical-align: top;

        &:first-child
        {
            margin-left: 0;
        }

        &:last-child
        {
            margin-right: 0;
        }
    }
}