@import "../../../../../Import/SCSS/classes";

.WidgetVideo
{
    &.NoPreview .WidgetVideoPlayer
    {
        background-color: transparent;

        &.ShowVideo
        {
            background-color: $color-black;
        }
    }
}

.WidgetVideoPlayer
{
    color: inherit !important;
}