@import "../../../Import/SCSS/classes";

.ViewAlert
{
    @include fade-in;
    @include overlay;

    background: color-gray(0.5);
}

.ViewAlertClose {

    display: block !important;
    position: absolute !important;
    top: 20px;
    right: 20px;
    width: 18px;
    color: $color-purple;
    z-index: 1;
}

.ViewAlertContent
{
    @include fade-up;

    padding: 15px 20px 20px;
    color: $color-black;
    background-color: $color-gray-light;
    border-radius: 3px;
    box-shadow: $box-shadow;
    text-align: left;
}

.ViewAlertContentText
{
    @include content;

    margin: 20px 0 10px;
}