
@import '../../../Import/SCSS/classes';

.Error
{
    position: relative;
    min-height: 32px;
    color: $color-red;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    transition: all $duration / 2 $timing-in-out;

    span
    {
        position: relative;
        top: 2px;
    }

    &.Empty
    {
        height: 0;
        opacity: 0;
    }
}

.ErrorButton
{
    display: block;
}

.ErrorIcon
{
    width: 1.5em !important;
    height: 1.5em !important;
    margin-right: 0.5em;
}