@import "../../../Import/SCSS/classes";

.Editor {

    @include fade-left;
    @include overlay;

    color: $color-black;
    background-color: $color-gray-light;
    border-left: 2px solid $color-purple;
    overflow: hidden;
}

.EditorClose {

    display: block !important;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
    color: $color-purple;
    z-index: 10000;
}

.EditorContent {

    @include overlay;

    right: -100px;
    padding: 20px 120px 155px 20px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

}

.EditorContentForm {

    position: relative;
    margin: 0 0 10px;

}

.EditorContentTray {

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 20px;
    background-color: $color-gray-light;
    border-top: 1px solid $color-gray;
    z-index: 1000;

}

.EditorContentTraySecond,
.EditorContentTrayThird {

    position: relative;
    white-space: nowrap;

    .IconItem {

        display: inline-block;
        position: relative;
        margin: 0 5px;
        vertical-align: top;

        &:first-child {

            margin-left: 0;
            
        }

        &:last-child {

            margin-right: 0;
            
        }
    
    
    }

}

.EditorContentTrayStatus {

    @include notice-text;
    @include no-select;
    @include text-overflow;

    margin: 10px 0;
    opacity: 0.5;

}

.EditorEmpty {

    @include notice;

}

.EditorForm {

    position: relative;
    margin: 10px 0;
    padding: 10px 10px 0.1px;
    border: 1px solid color-black( 0.1 );

}

.EditorFormTray {

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    background: linear-gradient( 0deg, color-gray(1) 50%, color-gray(0) );
    z-index: 100;

}

.EditorNotice {

    @include notice;

    color: $color-white;
    background-color: $color-purple;

}

.EditorResultDescription {

    position: relative;
    margin: 5px 0 0;

}

.EditorResultItem {

    @include fade-up;
    @include no-select;
    
}

.EditorResultItem,
.EditorTabLoadSearch {

    position: relative;
    margin: 10px 0;
    padding: 5px 10px 0.1px;
    background-color: color-white( 0.5 );

}

.EditorResultName {

    display: inline-block;
    position: relative;
    font-weight: $font-weight-bold;
    vertical-align: middle;

}

.EditorResultSecond {

    position: relative;
    margin: -2px -5px 3px;
    font-size: 12px;
    font-style: italic;
    opacity: 0.5;
    
    & > div {

        display: inline-block;
        position: relative;
        margin: 0 5px;
        vertical-align: middle;

    }

    .Icon {

        margin-right: 2px;

    }

}

.EditorResultTemplate {

    display: inline-block;
    position: relative;
    vertical-align: middle;

    &:before {

        position: relative;
        margin: 0 5px;
        content: "-";

    }

}

.EditorTab {

    position: relative;
    padding: 20px 0;

}

.EditorTabLoadSpinner {

    position: absolute !important;
    right: 15px;
    bottom: 15px;
    color: $color-black;

}

.EditorTree {

    @include field;
    @include no-select;

    label {

        @include field-label;

    }

}

.EditorTreeBranch {

    @include field-input-font;

    &.Active > .EditorTreeBranchLabel {

        color: $color-black;

    }

}

.EditorTreeBranches {

    position: relative;
    background-color: $color-white;

    & > .EditorTreeBranch:first-child > .EditorTreeBranchLabel {

        padding-top: 10px;

    }

    & > .EditorTreeBranch:last-child > :last-child {

        padding-bottom: 10px;

    }

}

.EditorTreeBranchLabel {

    @include text-overflow;

    padding: 5px 10px;
    cursor: pointer;
    color: color-black( 0.5 );

    @include hover {

        color: color-black( 1 );

    }

}

.EditorTreeBranchPad {

    display: inline-block;
    position: relative;
    height: 2px;
    margin-right: 5px;
    background-color: $color-pink;
    vertical-align: middle;

}

.EditorTreeBranchUnsaved {

    display: inline;
    position: relative;
    margin-left: 5px;
    opacity: .5;
    vertical-align: top;

}