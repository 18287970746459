
@import '../../../../Import/SCSS/classes';

.RepeaterField {

    @include field;

    position: relative;

    label {

        @include field-label;

    }

}

.RepeaterFieldAdd {

    margin-top: 10px;

}

.RepeaterFieldListField {

    margin: 0 !important;

}