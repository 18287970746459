@import "../../../../../Import/SCSS/classes";

.WidgetLeaderboard
{
    .WidgetError
    {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
    }
}

.WidgetLeaderboardItem
{
    @include list-item;
}

.WidgetLeaderboardItems
{
    @include list-items;
}