@import "../../../../../Import/SCSS/classes";

.WidgetRecommendedButton
{
    display: block;
    padding: 0 0 10px;
    text-align: center;
}

.WidgetRecommendedItem
{
    @include list-item;
    @include list-item-interactive;
}

.WidgetRecommendedItems
{
    @include list-items;
}