
@import "../../../../../Import/SCSS/classes";

.WidgetConditionalItem
{
    @include overlay;

    display: none;

    &.Active
    {
        display: block;
    }
}