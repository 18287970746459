@import "../../../Import/SCSS/classes";

.VideoPlayer
{
    @include overlay;

    iframe
    {
        @include overlay;

        width: 100%;
        height: 100%;
    }
}

.VideoPlayerContainer
{
    position: relative;

    &.Disabled
    {
        .VideoPlayerPoster
        {
            color: color-white(0.8) !important;
        }

        .VideoPlayerPosterIcon
        {
            opacity: .5;
        }
    }

    &.Show
    {
        .VideoPlayerPoster
        {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.VideoPlayerContent
{
    position: relative;
    padding-top: 56.25%;
    background-color: currentColor;
    overflow: hidden;
}

.VideoPlayerPoster
{
    @include overlay;

    cursor: pointer;

    &:focus
    {
        &:after
        {
            @include overlay;

            display: block;
            pointer-events: none;
            content: "";
        }
    }
}

.VideoPlayerPosterIcon
{
    @include button-behaviour;

    position: absolute !important;
    top: 50%;
    left: 50%;
    width: 40px !important;
    height: 40px !important;
    margin: -20px 0 0 -20px;
    padding: 8px;
    color: $color-white;
    border-radius: 40px;
    box-sizing: border-box;

    svg
    {
        position: relative;
        width: 100%;
        height: auto;
        transform: translateX(5%);
    }
}

.VideoPlayerPosterImage
{
    @include overlay;
    background-size: cover;
    background-position: center center;
}

.VideoPlayerPosterLabel
{
    position: absolute;
    right: 10%;
    bottom: 10%;
    left: 10%;
    font-style: italic;
    text-transform: none;
    pointer-events: none;
    animation: fade-up $duration / 2 ease;
}