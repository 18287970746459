@import "../../../../../Import/SCSS/classes";

.WidgetCarouselContent
{
    @include overlay;

    display: flex;
    flex-direction: column;
}

.WidgetCarouselDot
{
    @include button-behaviour;

    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px;
    margin: 0 8px;
    border: 1px solid currentColor;
    border-radius: 14px;

    &.Active
    {
        background-color: currentColor;
    }
}

.WidgetCarouselNavigation
{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px;
    text-align: center;
    z-index: 10;
}

.WidgetCarouselSlide
{
    @include overlay;

    display: block;
    opacity: 0;
    transition: opacity $duration $timing-in-out;
    pointer-events: none;
    overflow: hidden;

    &.Active
    {
        opacity: 1;
        pointer-events: initial;
    }

    &.Grayscale .WidgetCarouselSlideBackground
    {
        @include grayscale;
    }

    &.HasBackgroundOverlay .WidgetCarouselSlideBackground
    {
        @include content-background-overlay;
    }
}

.WidgetCarouselSlideBackground
{
    @include overlay;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.WidgetCarouselSlideContent
{
    position: relative;
    top: 50%;
    padding: 0 0 0 40px;
    max-width: 20em;
    transform: translateY(-50%);

    p
    {
        margin: $line-height / 2 0;

        &:first-child
        {
            margin-top: 0;
        }

        &:last-child
        {
            margin-bottom: 0;
        }
    }

}

.WidgetCarouselSlides
{
    position: relative;
    width: 100%;
    flex-grow: 1;
}

.WidgetCarouselTopBar
{
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    max-width: $w-content;
    height: 40px;
    margin: 0 auto;
    padding-left: 5px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 1em;
}

.WidgetCarouselTopBarCta
{
    @include button-behaviour;

    padding-right: 10px;
    font-weight: 400 !important;

    .ItemIcon
    {
        top: -0.125em;
        margin-right: 0.25em;
    }
}

.WidgetCarouselTopBarLink
{
    @include button-behaviour;

    font-size: 0.875em;
    font-weight: 400 !important;
}

.WidgetCarouselTopBarLinks
{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}