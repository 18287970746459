@import '../../../../Import/SCSS/classes';

.TaxonomyField
{
    @include field;

    position: relative;

    label 
    {
        @include field-label;
    }

    .IconMove svg
    {
        transform: scale(0.75);
        cursor: move;
    }
}

.TaxonomyFieldListItem
{
    @include fade-up;

    position: relative;
    border: 1px solid color-black(0.06125);

    &.Reordering
    {
        cursor: move;
        z-index: 1000;

        *
        {
            pointer-events: none;
        }

        .TaxonomyFieldTermInputContainer
        {
            display: none;
        }
    }

    &.Sorting
    {
        pointer-events: none;
        z-index: 1;

        .TaxonomyFieldListItemTransport
        {
            opacity: 0.5;
            background-color: $color-gray;
            outline: 1px dashed color-black(0.125);
        }
    }

    & + .TaxonomyFieldListItem
    {
        border-top: none;
    }
}

.TaxonomyFieldListItemButtons
{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}

.TaxonomyFieldListItemInsert
{
    position: relative;
    height: 2px;
    background-color: $color-purple;
}

.TaxonomyFieldListItemTransport
{
    position: relative;
    padding: 10px 10px 10px;
}

.TaxonomyFieldName
{
    @include field-label;
}

.TaxonomyFieldNameInput
{
    margin-top: 5px;
}

.TaxonomyFieldTermInputContainer
{
    position: relative;
}

.TaxonomyFieldTermInputSuggestions
{
    @include fade-up;

    display: flex;
    width: fit-content;
    flex-direction: row;
    margin-top: 5px;
    padding: 5px;
    color: $color-black;
    background-color: $color-white;
    border-radius: 5px;
}

.TaxonomyFieldTermInputSuggestionsItem
{
    @include button-behaviour;

    position: relative;
    font-size: 0.8em;

    &:after
    {
        content: ",";
    }

    &:last-child:after
    {
        content: "";
    }

    & + .TaxonomyFieldTermInputSuggestionsItem
    {
        margin-left: 5px;
    }

    @include hover
    {
        &:before
        {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 1px;
            background-color: currentColor;
            opacity: 0.25;
            content: "";
        }
    }
}

.TaxonomyFieldTermInputWrapper,
.TaxonomyFieldTermItem,
.TaxonomyFieldTermItemCursor,
.TaxonomyFieldTermItemUnselected
{
    @include no-select;

    display: flex;
    position: relative;
    width: fit-content;
    max-height: 26px;
    flex-direction: row;
    align-items: center;
    padding: 2px 5px;
    font-size: 0.8em;
    border-radius: 5px;
}

.TaxonomyFieldTermInputWrapper
{
    min-width: 85px;
    color: $color-black;
    background-color: color-black(0.06125);
    overflow: hidden;

    .WysiwygEditor:before
    {
        border: none !important;
    }
}

.TaxonomyFieldTermInput,
.TaxonomyFieldEditTermInput
{
    flex-grow: 1;
}

.TaxonomyFieldTermItem,
.TaxonomyFieldTermItemCursor,
.TaxonomyFieldTermItemUnselected
{
    height: 26px;
    padding: 0 5px 0 7px;
}

.TaxonomyFieldTermItem,
.TaxonomyFieldTermItemCursor
{
    color: $color-white;
    background-color: $color-purple;
    font-weight: 600;

    .IconButton
    {
        margin-left: 2px;
    }

    .IconButton + .IconButton
    {
        margin-left: -2px;
    }

    .IconMove
    {
        transition: opacity .25s ease;
        opacity: 0;
    }

    @include hover
    {
        .IconMove
        {
            opacity: 1;
        }
    }

    &.Moving
    {
        @include no-select;

        color: color-black(0.1);
        background-color: color-black(0.030625);
    }
}

.TaxonomyFieldTermItem,
.TaxonomyFieldTermItemUnselected
{
    &.HoverLeft:before,
    &.HoverRight:before
    {
        position: absolute;
        top: calc(50% - 10px);
        width: 3px;
        height: 20px;
        background-color: $color-purple;
        border-radius: 1px;
        content: "";
    }

    &.HoverLeft:before
    {
        left: -4px;
    }

    &.HoverRight:before
    {
        right: -4px;
    }
}

.TaxonomyFieldTermItem
{
    @include fade-up;
}

.TaxonomyFieldTermItemCursor
{
    position: absolute;
    padding-right: 41px;
    pointer-events: none;
    opacity: 0.25;
    z-index: 1000;

}

.TaxonomyFieldTermItemUnselected
{
    @include fade-up;

    display: none;
    background-color: color-black(0.06125);

    .TaxonomyFieldListItem.Reordering &
    {
        display: block;
    }
}

.TaxonomyFieldTerms
{
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}