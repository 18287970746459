@import "../../../../../Import/SCSS/classes";

.WidgetPoll
{
    position: relative;
    padding: 0 60px 0 40px;
    
    &.Expired
    {
        .WidgetPollAnswer
        {
            cursor: default;
        }

        .WidgetPollAnswerButton
        {
            background-color: transparent;
            border: 1px solid currentColor;
            transform: scale(1) !important;

            .Icon
            {
                mix-blend-mode: none !important;
            }
        }
    }

    &.Expired,
    &.Voted
    {
        .WidgetPollAnswerBar
        {
            height: 20px;
        }

        .WidgetPollAnswerBarFill
        {
            opacity: 1;
        }

    }

    &.Sending .WidgetPollAnswer
    {
        opacity: 0.5;
        pointer-events: none;
    }

    &.White
    {
        .WidgetPollAnswerButton .Icon
        {
            color: $color-white;
            mix-blend-mode: none !important;
        }
    }

    @include responsive-width(500px)
    {
        padding: 0 60px 0 20px;
    }
}

.WidgetPollAnswer
{
    position: relative;
    margin: 10px 0 0;
    cursor: pointer;
    transition: opacity .5s $timing-in-out;

    & > span
    {
        display: block;
        position: relative;
        min-height: $line-height;
        overflow: hidden;
    }

    @include hover
    {
        .WidgetPollAnswerButton
        {
            @include button-hover;
        }
    }

    @include active
    {
        .WidgetPollAnswerButton
        {
            transform: scale(1) !important;
        }
    }
}

.WidgetPollAnswerBar
{
    position: relative;
    height: 0px;
    margin: 5px 0 0;
    line-height: 20px;
    transition: all $duration-quick $timing-in-out;
}

.WidgetPollAnswerBarFill
{
    position: absolute;
    top: 8px;
    left: 0;
    height: 4px;
    background-color: currentColor;
    opacity: 0;
    transition: all $duration-quick $timing-in-out;

    span
    {
        position: absolute;
        top: -8px;
        left: calc( 100% + 5px );
    }
}

.WidgetPollAnswerButton
{
    @include button-transition;
    $s: 20px;
    position: relative;
    float: left;
    width: $s;
    height: $s;
    margin-right: 10px;
    border: 1px solid transparent;
    border-radius: $s;
    background-color: currentColor;
    transition: all $duration-quick $timing-in-out;

    .Icon
    {
        @include fade-up;
        display: block;
        top: 1px;
        width: 100% !important;
        height: 100% !important;
        color: currentColor;
        padding: 2px;
        //mix-blend-mode: exclusion;
        box-sizing: border-box;
        svg
        {
            stroke-width: 2;
        }
    }
}

.WidgetPollAnswerSpinner
{
    display: block;
    position: relative;
    float: left;
    margin-right: 10px;
}

.WidgetPollAnswers
{
    margin: 20px 0 0;
}

.WidgetPollContent
{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.WidgetPollTotal
{
    @include notice-small;
    position: absolute;
    right: 20px;
    bottom: 20px;
}