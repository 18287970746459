
@import '../../../Import/SCSS/classes';

.Slider {

    position: relative;
    overflow: hidden;

    &.NoGsap {

        .SliderWrapper {

            scroll-behavior: smooth;

        }

    }

    &.ScrollLeft .SliderButtonContainer.Left,
    &.ScrollRight .SliderButtonContainer.Right {

        opacity: 1;
        transform: translate3d(0,0,0);
        pointer-events: all;

    }

    &.ScrollLeft .SliderShadow.Left,
    &.ScrollRight .SliderShadow.Right {

        opacity: 1;

    }

}

.SliderButton {

    padding: 12px;
    color: $color-white;
    background-color: $color-purple;
    border-radius: 60px;

}

.SliderButtonContainer {

    position: absolute;
    top: 50%;
    margin: -25px 0 0 0;
    opacity: 0;
    z-index: 10;
    transition: all $duration-quick $timing-in-out;
    pointer-events: none;

    &.Left {

        left: 5px;
        transform: translate3d(20px,0,0);

    }

    &.Right {

        right: 5px;
        transform: translate3d(-20px,0,0);

    }

}

.SliderContent {

    position: relative;
    height: 100%;
    margin-bottom: -30px;

}

.SliderShadow {

    position: absolute;
    top: 5px;
    bottom: -10px;
    width: 10px;
    opacity: 0;
    z-index: 10;
    transition: all $duration-quick $timing-in-out;
    pointer-events: none;
    mask-image: linear-gradient( 0deg, color-black(0) 0%, color-black(1) 10%, color-black(1) 90%, color-black(0) 100% );

    &.Left {

        left: 0;
        background-image: linear-gradient( 90deg, color-black(0.3), color-black(0) );

    }

    &.Right {

        right: 0;
        background-image: linear-gradient( 270deg, color-black(0.3), color-black(0) );

    }

}

.SliderWrapper {

    position: relative;
    height: 100%;
    padding: 10px 0 40px;
    overflow: auto;
    overflow-y: hidden;
    text-align: center;
    white-space: nowrap;
    //mask-image: linear-gradient( 90deg, color-black(0) 0%, color-black(1) 1%, color-black(1) 99%, color-black(0) 100% );

    & > * {

        @include fade-up;

        display: inline-block;
        position: relative;
        vertical-align: top;

    }

}