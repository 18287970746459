
@import '../../../../Import/SCSS/classes';

.ImageField {

    @include field;

    position: relative;
    cursor: pointer;

    label {

        @include field-label;

    }

    .Input {

        @include field-input;
        @include text-overflow;

        width: auto;
        padding-right: 40px;
        overflow: hidden;

        span {

            @include no-select;

            line-height: $h-field;

        }

        .Icon {

            @include field-icon;

        }

    }

    @include hover {

        .Input {

            &,
            .Icon {

                color: $color-black;

            }

        }

    }

    &.Disabled {

        .ImageFieldPreview {

            opacity: 0.5;
            pointer-events: none;

        }

        .Input {

            opacity: 0.5;

            &,
            .Icon {

                color: color-black( 0.5 ) !important;

            }

        }

    }

}

.ImageFieldPreview {

    float: left;
    position: relative;
    width: $h-field;
    height: $h-field;
    margin-right: 10px;
    color: $color-white;
    background-color: $color-purple;
    transition: all $duration-quick $timing-in-out;

    .Icon {

        display: block;
        position: relative;
        top: ( $h-field - 24 ) / 2;
        left: ( $h-field - 24 ) / 2;

    }

    .LoadImage {

        @include overlay;

        background-size: cover;

    }

    &.Filled {

        .Icon {

            opacity: 0;
            transform: scale(0.5);
            transition: all $duration-quick $timing-in-out;

        }

        @include hover {

            .Icon {

                opacity: 1;
                transform: scale(1);

            }

            .LoadImage {

                opacity: 0.5;

            }

        }

    }

}