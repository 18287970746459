
@import '../../../Import/SCSS/classes';

.WidgetGalleryButton {

    display: block;
    padding: 0 0 10px;
    text-align: center;

}

.WidgetGalleryContent {

    position: relative;
    height: 300px;

}

.WidgetGalleryEmpty {

    @include notice;

}

.WidgetGalleryItem {

    @include list-item;
    @include list-item-interactive;
    @include no-select;

    height: 60px;
    padding: 5px 10px;
    cursor: pointer;

    &.Selected {

        background-color: color-black( 0.1 ) !important;

    }

}

.WidgetGalleryItemContent {

    padding: 0 15px !important;

}

.WidgetGalleryItemCount,
.WidgetGalleryItemTemplate,
.WidgetGalleryItemUpdated {

    display: inline-block;
    position: relative;
    font-size: 12px;
    font-style: italic;
    vertical-align: top;

}

.WidgetGalleryItemCount {

    &:before {

        margin: 0 2px;
        content: "-";

    }

}

.WidgetGalleryItemDescription {

    position: relative;

}

.WidgetGalleryResults {

    @include list-items;
    
    margin: 0 -10px;

}

.WidgetGalleryTray {

    position: relative;
    padding-top: 10px;

    .ButtonContainer {

        margin: 0 !important;
        
    }

}