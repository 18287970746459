@import '../../../Import/SCSS/classes';

.Calendar
{
    position: relative;
    direction: ltr;
    cursor: pointer;
    white-space: normal;
}

.CalendarDate
{
    display: inline-block;
    position: relative;
    width: (100% / 7);
    padding-top: (100% / 7);

    span
    {
        display: block;
        position: absolute;
        top: calc(50% - 10px);
        right: 0;
        left: 0;
        text-align: center;
        line-height: 20px;
    }

    &.Disabled
    {
        span
        {
            color: $color-gray;
        }
    }

    &.Outside
    {
        span
        {
            opacity: .5;
        }
    }

    &.RedLetter
    {
        span
        {
            color: $color-red;
        }
    }

    &.Selected
    {
        font-weight: 600;
    }

    &.Today
    {
        font-style: italic;
    }

    &:nth-child(7n-1)
    {
        &:after
        {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            background-color: currentColor;
            opacity: .25;
            content: "";
        }
    }
}

.CalendarDates
{
    position: relative;
    padding: 5px;
    line-height: 0;
}

.CalendarHeading
{    
    &:after
    {
        display: block;
        clear: both;
        content: "";
    }
}

.CalendarMonth
{
    text-align: center;
    text-transform: capitalize;
    line-height: 32px;
}

.CalendarNext
{
    display: block;
    position: relative;
    top: 7px;
    right: 7px;
    float: right;
}

.CalendarPrevious
{
    display: block;
    position: relative;
    top: 7px;
    left: 7px;
    float: left;
}