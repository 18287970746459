
@import "../../../../../Import/SCSS/classes";

.WidgetHijackContentAlign
{
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
}

.WidgetHijackContentItem
{
    margin: 0 10px;
}

.WidgetHijackContentItems
{
    padding: 10px 0;
    text-align: left;
}

.WidgetHijackContentList
{
    @include list-items;
}

.WidgetHijackLeaderboardWrapper
{
    .WidgetError
    {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
    }
}

.WidgetHijackLeaderboardItem
{
    @include list-item;
}

.WidgetHijackLeaderboardItems
{
    @include list-items;
}