
@import "../../../../../Import/SCSS/classes";

.WidgetLink
{

    @include no-select;
    cursor: pointer;

    .WidgetBackground
    {
        @include button-transition;
        transform: scale(1.1);
    }

    @include hover
    {
        .WidgetBackground
        {
            transform: scale(1);
        }

        .WidgetLinkContent
        {
            transform: scale(1.1);
        }
    }

    &.Disabled
    {
        .WidgetBackground
        {
            transform: scale(1.1) !important;
        }

        .WidgetLinkContent
        {
            transform: scale(1) !important;
        }

        .WidgetLinkContent
        {
            opacity: 0.5;
        }
    }
}

.WidgetLinkContent
{
    @include overlay;
    @include button-transition;

    display: block;

    .AlignBottom > &
    {
        transform-origin: bottom center;
    }
    
    .AlignLeft > &
    {
        transform-origin: center left;
    }

    .AlignRight > &
    {
        transform-origin: center right;
    }

    .AlignTop > &
    {
        transform-origin: top center;
    }

    .AlignLeft.AlignTop > &
    {
        transform-origin: top left;
    }

    .AlignLeft.AlignBottom > &
    {
        transform-origin: bottom left;
    }

    .AlignRight.AlignTop > &
    {
        transform-origin: top right;
    }

    .AlignRight.AlignBottom > &
    {
        transform-origin: bottom right;
    }
}

.WidgetLinkContentLabel
{
    @include heading-2;

    position: absolute;

    p
    {
        margin: 14px 0;

        &:first-child
        {
            margin-top: 0;
        }

        &:last-child
        {
            margin-bottom: 0;
        }
    }
}