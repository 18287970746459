
@import '../../../Import/SCSS/classes';

.Collapsable {

    position: relative;
    overflow: hidden;

    &.Expanded,
    &.SemiCollapsed {
        
        .CollapsableContent {

            opacity: 1;

        }

    }

}

.CollapsableContent {

    opacity: 0;
    transition: opacity .75s $timing-in-out;

}