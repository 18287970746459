
@import '../../../Import/SCSS/classes';

.DraftsOverviewContent {

    position: relative;
    height: 300px;

}

.DraftsOverviewItem {

    @include list-item;
    @include list-item-interactive;
    @include no-select;

    height: 60px;
    padding: 5px 10px;
    cursor: pointer;

    &.Selected {

        background-color: color-black( 0.1 ) !important;

    }

}

.DraftsOverviewItems {

    @include list-items;
    
    margin: 0 -10px;

}

.DraftsOverviewTray {

    position: relative;
    padding-top: 10px;

    .ButtonContainer {

        margin: 0 !important;
        
    }

}