
@import '../../../Import/SCSS/classes';

.IconButton {

    @include button-behaviour;

    display: inline-block;
    position: relative;
    line-height: 0;
    vertical-align: middle;

    .Icon,
    .Spinner {

        display: block !important;

    }

    &.Disabled {

        @include button-disabled;

        pointer-events: none;

    }

    & + .IconButton {

        margin-left: 3px;

    }

}