@import "../../../../Import/SCSS/classes";

.ContentField
{
    @include field;

    position: relative;

    label
    {
        @include field-label;
    }

    &.Flip
    {
        .StickyAlign
        {
            bottom: calc(100% + 37px);
        }

        .StickyContent
        {
            position: absolute;
        }
    }

    &.Expand
    {
        .ContentFieldContent,
        .ContentFieldTextField
        {
            z-index: 4001;
        }

        .ContentFieldIcon,
        .ContentFieldSpinner
        {
            z-index: 4002;   
        }
    }
}

.ContentFieldContent
{
    position: relative;
    background-color: $color-white;
}

.ContentFieldEmpty
{
    @include item;
    font-style: italic;
}

.ContentFieldIcon
{
    @include field-icon;
}

.ContentFieldInput
{
    position: relative;
}

.ContentFieldItem
{
    @include item;

    height: 20px;
    padding-right: 30px;

    &.Disabled
    {
        opacity: 0.5;
        //pointer-events: none;
    }

    &.ResultItem
    {
        @include item-interactive;
    }
    
    span
    {
        position: relative;
        top: 1px;
    }
}

.ContentFieldItemId
{
    opacity: 0.5;

    &:before
    {
        content: " ";
    }
}

.ContentFieldItemPreview,
.ContentFieldItemSpinner
{
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.ContentFieldItemRemove
{
    display: block !important;
    position: absolute !important;
    top: 6px !important;
    right: 10px;

    .ContentFieldItem:first-child &
    {
        top: 12px !important;
    }
}

.ContentFieldSpinner
{
    @include field-icon;

    .Icon
    {
        display: block;
    }
}

.ContentFieldTextField
{
    margin: 0 !important;
}