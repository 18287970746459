@import '../../../Import/SCSS/classes';

.ButtonContainer
{
    @include fade-up;
    @include field;

    display: inline-block;
    position: relative;
    color: $color-purple;
    box-sizing: border-box;

    .ButtonContainer + & .Button
    {
        margin-left: 10px;
    }

    &.Disabled .Button
    {
        @include button-disabled;
        pointer-events: none;
    }

    &.Done .Button,
    &.Down .Button,
    &.Loading .Button
    {
        transform: scale( 1 ) !important;
    }

    &.Done .Button,
    &.Loading .Button
    {
        .ButtonContent
        {
            opacity: 0;
        }
    }

    &.Hollow .Button
    {
        @include button( currentColor, currentColor );
        font-weight: $font-weight;
        background-color: transparent;
    }

    &.Red .Button
    {
        @include button;
    }

    &.White .Button
    {
        @include button( $color-white, $color-black );
    }

    &.Big
    {
        display: block;
        margin: 10px auto;

        .Button
        {
            margin: 0;
            padding: 0 50px;
            font-size: 16px;
            line-height: 58px;
        }

        .ButtonContent
        {
            opacity: 1 !important;
        }

        .ButtonIcon
        {
            right: 15px;
            width: auto !important;
        }

        .ButtonSpinner
        {
            top: 15px;
            right: 10px;
        }

        &.Loading .ButtonContent
        {
            opacity: .5 !important;
        }
    }
}

.ButtonContent
{
    transition: opacity .25s $timing-in-out;
}

.ButtonIcon
{
    $s: 80%;
    $p: ( 100% - $s ) / 2;
    @include fade-up;

    position: absolute !important;
    top: $p;
    right: $p;
    width: $s !important;
    height: $s !important;
}

.ButtonSpinner
{
    position: absolute !important;
    top: 3px;
    right: 2px;
    color: currentColor !important;
}