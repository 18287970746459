@import '../../../Import/SCSS/classes';

.CenterContentScroll
{
    @include overlay;
    
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.CenterContentContainer
{
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
}

.CenterContentAlign
{
    display: table-cell;
    position: relative;
    vertical-align: middle;
}

.CenterContent
{
    position: relative;
    margin: 0 auto;
    text-align: center;
}