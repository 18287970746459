
@import '../../../Import/SCSS/classes';

.ImageItem {

    @include fade-up;

    position: relative;

    &.Active {

        .ImageItemContent:after {

            @include overlay;

            border: 2px solid $color-white;
            content: "";

        }

    }

    &.Disabled {

        pointer-events: none;

        .ImageItemPreview.Loaded {

            opacity: 0.5 !important;

        }

    }

    &.Duplicate {

        .ImageItemPreview.Loaded {

            opacity: 0.5 !important;

        }

    }

}

.ImageItemCenter {

    position: absolute;
    top: 50%;
    left: 50%;
    color: $color-white;
    transform: translate(-50%,-50%);
    z-index: 10;

}

.ImageItemContent {

    @include button-behaviour( 1.03 );

    position: relative;
    padding: 0 0 120%;
    overflow: hidden;
    background-color: $color-black;
    box-shadow: $box-shadow-small;

}

.ImageItemPreview {

    @include content-background;
    @include overlay;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

}

.ImageItemProgress {

    position: absolute;
    right: 10px;
    bottom: 5px;
    left: 10px;

}

.ImageItemWrapper {

    position: relative;
    padding: 5px;

}