
@import '../../../Import/SCSS/classes';

.Group {

    position: relative;

    &.Collapsed {

        .GroupContent {

            pointer-events: none;

        }

    }

    &.Expanded {

        .GroupTitleIcon {

            transform: rotate( 180deg );

        }

    }

}

.GroupContent {

    margin-bottom: 10px;

    .CollapsableContent {

        padding: 5px 10px 1px;

    }

    &.Finished {

        overflow: visible !important;

    }

}

.GroupTitle {

    position: relative;
    cursor: pointer;

}

.GroupTitleHeading {

    @include heading-3;

}

.GroupTitleIcon {

    @include button-transition;

    margin-left: 5px;

}