
@import "../../../../../Import/SCSS/classes";

.WidgetMulti
{
    & > .WidgetToolbarWrapper
    {
        z-index: 200000;
    }
}

.WidgetMultiContent
{
    margin: -5px;
    padding: 0;
}

.WidgetMultiToolbarContainer
{
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 3000;
}