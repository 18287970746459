
@import "../../../../../Import/SCSS/classes";

$wh: 200px;

.WidgetBannerLinks.LinksBelow
{
    display: flex;
    position: relative;
    height: 100%;
    padding: 0 0 0 40px;

    .WidgetBannerLinksContent
    {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .WidgetBannerLinksItem
    {
        margin: 0 10px;

        .ContentItemLabel
        {
            font-size: 1em;
            line-height: 1.25;
        }
    }

    .WidgetBannerLinksItems
    {
        width: 100%;
        margin: 20px -10px 0;
        padding: 0;

        .SliderWrapper
        {
            text-align: left;
        }
    }
}

.WidgetBannerLinks.LinksRight {

    position: relative;
    padding: 0 0 0 40px;

    &.Narrow {

        padding: 40px;

        .WidgetBannerLinksContent {

            top: 50%;
            height: auto;
            max-width: none;
            margin: 0;
            transform: translateY(-50%);

        }

        .WidgetBannerLinksContentText {

            top: auto;
            float: none;
            max-width: none;
            transform: none;

        }

        .WidgetBannerLinksItem {

            margin: 0 10px;

            .ContentItemLabel {

                font-size: 1em;
                line-height: 1.25;

            }

        }

        .WidgetBannerLinksItems {

            margin: 0 -10px;
            padding: 40px 0 0;

            .SliderWrapper {

                height: auto !important;

            }

        }

        @include responsive-width( 500px ) {

            padding: 20px;

        }

    }

    .WidgetBannerLinksContent {

        position: relative;
        top: 50%;
        //height: $wh;
        max-width: $w-content;
        //margin: $wh / -2 auto 0;
    
        p {
    
            margin: $line-height / 2 0;
    
            &:first-child {
    
                margin-top: 0;
    
            }
    
            &:last-child {
    
                margin-bottom: 0;
    
            }
    
        }
    
        &:after {
    
            display: block;
            clear: both;
            content: "";
    
        }
    
    }
    
    .WidgetBannerLinksContentText {
    
        position: relative;
        top: 50%;
        float: left;
        max-width: 20em;
        transform: translateY(-50%);
    
    }
}

.WidgetBannerLinksItem {

    margin: 0 20px;

}

.WidgetBannerLinksItems {

    padding: 10px 0 10px 30px;

}