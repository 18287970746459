
@import "../../../Import/SCSS/classes";

.LoginDialog {

    .OverlayDialogClose {

        display: none;

    }

}