
@import '../../../Import/SCSS/classes';

.Preview {

    position: relative;
    color: $color-white;
    background-color: $color-purple;

}

.PreviewIcon {

    position: absolute;
    top: 10%;
    left: 10%;
    width: 80% !important;
    height: 80% !important;

    svg {

        stroke-width: 0.75 !important;

    }

}

.PreviewImage {

    @include overlay;

    background-repeat: no-repeat;
    background-size: cover;

    &:after {

        @include overlay;

        background-color: color-black( 0.5 );
        content: "";

    }

}