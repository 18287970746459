
@import "../../../../../Import/SCSS/classes";

.WidgetLearningPlansAlign
{
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
}

.WidgetLearningPlansContent
{
    position: relative;
    top: 50%;
    height: 283px;
    transform: translateY(-50%);

    h2
    {
        margin: 0 !important;
        padding: 0 0 5px 40px;
    }
}



.WidgetLearningPlansFilter
{
    span,
    .Icon,
    .Input
    {
        color: inherit !important;
        background-color: transparent;
    }
}

.WidgetLearningPlansItem
{
    margin: 0 20px;
}

.WidgetLearningPlansItems
{
    //height: 280px;
    ///margin: 0 -30px;
    padding: 10px 0;
    
    .SliderWrapper
    {
        text-align: left;
    }
}