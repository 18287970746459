
@import '../../../Import/SCSS/classes';

.CommentItem
{
    @include list-item;

    &.Deleted
    {
        .ItemContent,
        .ItemPreview
        {
            opacity: .5;
            transition: opacity $duration-quick $timing-in-out;
        }

        @include hover
        {
            .ItemContent,
            .ItemPreview
            {
                opacity: .75;
            }
        }

        .CommentItemTitleLink:before
        {
            content: "[DELETED] ";
        }
    }
}

.CommentItemBody
{
    position: relative;
    margin: 3px 0;
    
    p
    {
        position: relative;
        margin: 22px 0;

        &:first-child
        {
            margin-top: 0;
        }

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    &:empty:after
    {
        opacity: .5;
        font-style: italic;
        content: "Empty comment"
    }

}

.CommentItemForm
{
    position: relative;
    margin: 0 0 20px;

    .TextareaField
    {
        margin: 0 !important;
    }
}

.CommentItemSub
{
    position: relative;
    margin: 0 0 0 10px;
    background-color: color-white(0.25);

    .CollapsableContent
    {
        padding: 10px 10px 0;
    }
}

.CommentItemTitle
{
    position: relative;
    font-size: 12px;
    line-height: 16px;
}

.CommentItemTitleCreated
{
    display: inline-block;
    position: relative;
}

.CommentItemTitleLink
{
    position: relative;
    margin-right: 3px;
    font-weight: $font-weight-headline;

    @include hover
    {
        text-decoration: underline;
    }
}

.CommentItemToolbar
{
    position: relative;
    font-size: 12px;
    white-space: nowrap;
}

.CommentItemToolbarCounter,
.CommentItemToolbarItem
{
    display: inline-block;
    position: relative;
    vertical-align: middle;

    &:first-child
    {
        margin-left: 0;
    }

    &:last-child
    {
        margin-right: 0;
    }
}

.CommentItemToolbarCounter
{
    top: -1px;
    margin: 0 30px 0 2px;
}

.CommentItemToolbarItem
{
    margin: 0 2px 0 5px;
}