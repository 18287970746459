
@import '../../../../Import/SCSS/classes';

.CheckboxField {

    @include field;

    position: relative;
    margin-bottom: 0;

    label {

        @include field-label;

    }

    .Input {

        @include button-behaviour;
        @include field-box;

        background-color: $color-white;

        .Icon {

            display: block;
            width: 100%;
            height: 100%;

        }

    }

    .CheckboxField + & {

        margin-top: 0;

        .CheckboxFieldContent {

            padding-top: 0;

        }

    }

    &.Disabled {
        
        .Input {

            @include button-disabled;

        }

    }

}

.CheckboxFieldContent {

    @include field-input-font;

    $l: 20px;
    $p: ( $h-field - $l ) / 2;

    padding: $p 10px;
    background-color: transparent;
    line-height: $l;

}