@import '../../../../Import/SCSS/classes';

.FilterField {

    @include field;
    @include no-select;

    position: relative;
    cursor: pointer;

    label {

        @include field-label;

    }

    .FilterFieldForm {

        label {

            padding: 5px 10px 0;

        }

        .Field {

            margin: 0 !important;

        }

    }

    .FilterFieldIcon {

        @include field-icon;

    }

    .FilterFieldInput {

        @include field-input;
        @include text-overflow;

        width: 100%;
        padding-right: 30px;
        box-sizing: border-box;
        font-style: italic;

    }

    .FilterFieldLabel {

        @include no-select;

        line-height: $h-field;

    }

    &.Expand {

        .FilterFieldIcon,
        .FilterFieldLabel {

            color: $color-black;

        }
        
    }

    @include hover {

        .FilterFieldInput {

            &,
            .FilterFieldIcon {

                color: $color-black;

            }

        }

    }

}