@import "../../../Import/SCSS/classes";

.Wysiwyg
{
    position: relative;

    figure
    {
        display: inline;
    }

    h1, h2, h3, h4, h5, h6
    {
        clear: both;
    }

    &.MinimalLayout
    {
        .WysiwygEditor
        {
            &:before
            {
                @include overlay;

                display: block;
                pointer-events: none;
                content: "";
                opacity: 0.15;
            }
        }

        .WysiwygToolbar
        {
            display: none;
            position: absolute;
            top: calc(100% + 3px);
            right: 0;
            padding: 3px;
            background-color: $color-gray-light;
            border-radius: 3px;
            box-shadow: $box-shadow;
            z-index: 1;
        }

        &.Focus
        {
            .WysiwygEditor:before
            {
                border: 1px dashed currentColor;
            }

            .WysiwygToolbar
            {
                display: flex;
            }
        }
    }

    &.NoFormatting
    {
        .WysiwygToolbar
        {
            display: none !important;
        }
    }

    &.NormalLayout
    {
        .WysiwygEditor
        {
            padding: 10px;
            color: $color-black;
            background-color: $color-white;
            text-align: left;
        }

        .WysiwygToolbar
        {
            padding: 5px 0;
            opacity: 0.125;
        }

        &.Focus
        {
            .WysiwygToolbar
            {
                opacity: 1;
            }
        }
    }

    .rdw-center-aligned-block
    {
        text-align: center;
    }

    .rdw-left-aligned-block
    {
        text-align: left;
    }

    .rdw-right-aligned-block
    {
        text-align: right;
    }

    .rdw-image-imagewrapper
    {
        img
        {
            display: block;
            width: 100%;
        }
    }

    .public-DraftEditorPlaceholder-inner
    {
        position: absolute;
        pointer-events: none;
        opacity: 0.5;
    }
}

.WysiwygEditor
{
    position: relative;

    a + img
    {
        position: absolute;
        transform: translateY(-25%) scale(0.75);
    }
}

.WysiwygImageBlock
{
    position: relative;
    background-color: color-black(0.5);
    text-align: center;
    box-sizing: border-box;

    &.AlignLeft
    {
        clear: left;
        float: left;
        width: calc(50% - 10px);
        margin-right: 10px;
    }

    &.AlignRight
    {
        clear: right;
        float: right;
        width: calc(50% - 10px);
        margin-left: 10px;
    }

    &.Loading .WysiwygImageBlockImage
    {
        position: absolute !important;
        opacity: 0;
    }

}

.WysiwygImageBlockImage
{
    position: relative;
    background-size: contain;
}

.WysiwygImageBlockSpinner
{
    margin: 5px;
}

.WysiwygImageBlockToolbarButton
{
    @include button-behaviour;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    margin: 0 !important;
    background-color: $color-gray-light;
    border-radius: 3px;
    z-index: 1;

    &, *
    {
        color: $color-black !important;
    }

    &.Active,
    &.Active *
    {
        color: $color-white !important;
        background-color: $color-purple;
    }
}

.WysiwygImageBlockToolbar
{
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    top: 5px;
    left: 5px;
    right: 5px;
    opacity: 0;

    .WysiwygImageBlock:hover &
    {
        opacity: 1;
    }
}

.WysiwygImageBlockToolbarLeft,
.WysiwygImageBlockToolbarRight
{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.WysiwygToolbar
{
    display: flex;
    flex-direction: row;
    align-items: center;

    &, *
    {
        color: $color-black !important;
    }
}

.WywiwygToolbarButton
{
    padding: 1px;

    &.Active
    {
        svg
        {
            stroke-width: 2;
        }
    }
}

.WywiwygToolbarButtons
{
    display: flex;
    position: relative;
    padding-right: 5px;
    flex-direction: row;
    align-items: center;

    .WywiwygToolbarButtons + &,
    .WywiwygToolbarSelectField + &
    {
        padding-left: 5px;
    }
}

.WywiwygToolbarButtonWrapper
{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding-left: 2px;

    .WywiwygToolbarButtons + &,
    .WywiwygToolbarSelectField + &
    {
        padding-left: 5px;
    }
}

.WywiwygToolbarColorPicker
{
    display: flex;
    position: relative;
    height: 20px;
    padding: 0 5px 0 7px;
    flex-direction: row;
    align-items: center;
    
    .ColorFieldPicker
    {
        width: 14px !important;
        height: 14px !important;
        margin: 0 !important;
    }

    .Field
    {
        top: -1px;
        height: 14px !important;
        margin: 0 !important;
    }
}

.WywiwygToolbarSelectField
{
    height: 20px !important;
    margin: 0 !important;

    span
    {
        position: relative;
        top: -0.1em;
        font-size: 11px;
        line-height: 1 !important;
    }

    .Icon
    {
        bottom: 1px !important;
        right: 5px !important;
    }

    .Input
    {
        height: 20px !important;
        min-width: 80px !important;
        background-color: transparent !important;
    }
}

.WywiwygToolbarButtons,
.WywiwygToolbarColorPicker,
.WywiwygToolbarSelectField
{
    .WywiwygToolbarButtons + &:before,
    .WywiwygToolbarSelectField + &:before
    {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px; 
        background-color: currentColor;
        content: "";
        opacity: 0.15;
    }
}