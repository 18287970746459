@import "../../../Import/SCSS/classes";

.ViewArticles
{
    @include fade-in;
    @include overlay;

    background: color-gray(0.5);
}

.ViewArticlesAccessNotice
{
    @include notice;

    margin: 0;
    padding-left: 0;
    opacity: 0.5;
}

.ViewArticlesBlocks
{
    display: flex;
    position: relative;
    min-height: calc(100vh - 300px);
    flex-grow: 1;
}

.ViewArticlesClose {

    display: block !important;
    position: absolute !important;
    top: 20px;
    right: 20px;
    width: 18px;
    color: $color-purple;
    z-index: 1;
}

.ViewArticlesCommand
{
    display: inline;
    margin: 0 0.2em;
    font-weight: 400;
    font-size: 0.9em;

    .Icon
    {
        position: relative;
        top: -0.1em;
        margin-right: 0.15em;
    }
}

.ViewArticlesContent
{
    @include fade-up;

    padding: 15px 20px 20px;
    color: $color-black;
    background-color: $color-gray-light;
    border-radius: 3px;
    box-shadow: $box-shadow;
    text-align: left;
}

.ViewArticlesContentBlock
{
    padding: 20px;
}

.ViewArticlesContentBlocks
{
    position: relative;
    width: 100%;
    max-width: 938px;
    margin: 40px auto;
    background-color: $color-white;
    border: 1px solid #d1d1d1;
}

.ViewArticlesEditor
{
    position: relative;
    flex-grow: 1;
}

.ViewArticlesEditorContainer
{
    position: relative;
    z-index: 1;
}

.ViewArticlesEditorNotice
{
    @include notice;

    color: $color-white;
    background-color: $color-purple;
}

.ViewArticlesEditorWrapper
{
    display: flex;
    margin: 20px 0 5px;
    flex-direction: row;
}

.ViewArticlesEmpty
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.ViewArticlesError
{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ViewArticlesHome
{
    h2
    {
        margin: 0;
        padding: 30px 0 20px;
    }

    &.HasContent h2
    {
        padding-bottom: 10px;
    }
}

.ViewArticlesHomeContent
{
    @include no-select;
    
    max-width: 700px;
    margin: 0 0 20px;
    cursor: pointer;

    .Item
    {
        display: inline;
        text-decoration: underline;
    }
}

.ViewArticlesImageField
{
    @include button-behaviour;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute !important;
    top: 5px;
    left: 5px;
    width: 26px;
    height: 26px;
    margin: 0 !important;
    background-color: $color-gray-light;
    border-radius: 3px;
    z-index: 1;
    opacity: 0;

    &, *
    {
        color: $color-black !important;
    }

    .ContentBlock:hover &
    {
        opacity: 1;
    }
}

.ViewArticlesImageWrapper
{
    position: relative;
}

.ViewArticlesLabel
{
    @include field-label;
}

.ViewArticlesList
{
    position: relative;
    margin: 20px 0 0px;

    .HasTitle &
    {
        margin: 0;
    }
}

.ViewArticlesListItem
{
    .ItemInfo
    {
        display: flex;
        align-items: center;

        &.ItemHidden
        {
            opacity: 0 !important;
        }
    }

    .ItemUser
    {
        margin: 5px 0;
    }

    .User,
    .UserAvatar
    {
        display: block;
        line-height: 1;
    }

    .UserAvatar
    {
        margin-right: 5px;
        background-color: $color-purple;
    }

    @include hover
    {
        .ItemHidden
        {
            opacity: 0.5 !important;
        }
    }
}

.ViewArticlesListItemInfo
{
    display: flex;
    position: relative;
    top: 0.1em;
    margin-left: 5px;
    padding-left: 5px;
    font-size: 12px;
    font-weight: $font-weight;
    
    &:before
    {
        display: block;
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 0;
        width: 1px;
        background-color: currentColor;
        content: "";
        opacity: 0.5;
    }
}

.ViewArticlesListItemItems
{
    display: flex;

    .IconItem
    {
        margin: 0 2px;
    }
}

.ViewArticlesListItem
{
    @include list-item;
    @include list-item-interactive;
}

.ViewArticlesListItems
{
    @include list-items;
}

.ViewArticlesListWrapper
{
    position: relative;
    min-height: 400px;
}

.ViewArticlesSidebar
{
    position: relative;
    width: 340px;
    margin-right: -20px;
    border-left: 1px solid $color-gray;

    .CollapsableContent
    {
        padding: 5px 0 1px !important;
    }
}

.ViewArticlesSidebarContent
{
    position: relative;
    padding: 0 20px 20px;
}

.ViewArticlesTab
{
    position: relative;
    z-index: 1;
}

.ViewArticlesTrayButtons
{
    display: flex;
    margin: 10px 0 0;
    align-items: center;

    .ButtonContainer
    {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.ViewArticlesTrayStatus
{
    @include notice-text;
    @include no-select;
    @include text-overflow;

    margin: 0 0 0 10px;
    opacity: 0.5;
}

.ViewArticlesUnselected
{
    @include notice;

    margin: 0;
    padding: 0;
    color: color-black(0.25);
}