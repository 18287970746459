
@import '../../../Import/SCSS/classes';

$wrapper-shadow-opacity: 0.1;

.ScrollView {

    @include overlay;

    overflow: hidden;

    &.ScrollDown:after {

        box-shadow: 0 -3px 4px color-black( $wrapper-shadow-opacity );

    }

    &.ScrollUp:before {

        box-shadow: 0 3px 4px color-black( $wrapper-shadow-opacity );

    }

    &:after,
    &:before {

        position: absolute;
        right: 0;
        left: 0;
        height: 50px;
        content: "";
        z-index: 10;
        pointer-events: none;
        transition: all $duration-quick $timing-in-out;

    }

    &:after {

        top: 100%;

    }

    &:before {

        bottom: 100%;

    }

}

.ScrollViewContent {

    @include overlay;

    right: -50px;
    overflow: auto;

}

.ScrollViewContentWrapper {

    position: relative;
    width: calc( 100% - 50px );
    min-height: 100%;
    overflow: hidden;

}