@import '../../../../Import/SCSS/classes';

.DateField
{
    @include field;
    @include no-select;

    position: relative;
    cursor: pointer;

    label
    {
        @include field-label;
    }

    .Input
    {
        @include field-input;

        color: color-black(0.5) !important;
        line-height: 40px;

        @include hover
        {
            color: color-black(1) !important;
        }
    }
}

.DateFieldChevron
{
    @include field-icon;
}