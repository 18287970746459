@import '../../../../Import/SCSS/classes';

.SelectField
{
    @include field;
    @include no-select;

    position: relative;
    cursor: pointer;

    i
    {
        font-style: italic;
        font-weight: 300;
        opacity: 0.5;
    }

    label
    {
        @include field-label;
    }

    span
    {
        @include no-select;
        line-height: $h-field;
    }

    .Input
    {
        @include field-input;

        .Icon
        {
            @include field-icon;
        }
    }

    .Option
    {
        @include item;
        @include item-interactive;

        &.Selected
        {
            font-weight: 600;
        } 
    }

    &.Disabled
    {
        pointer-events: none;

        span,
        .Icon
        {
            color: color-black(0.5) !important;
        }

        .Input
        {
            opacity: 0.5;
        }
    }

    &.Expand
    {
        span,
        .Icon
        {
            color: $color-black;
        }
    }

    &.Flip
    {
        .StickyAlign
        {
            bottom: calc(100% + 37px);
        }

        .StickyContent
        {
            position: absolute;
        }
    }

    @include hover
    {
        .Input
        {
            &,
            .Icon
            {
                color: $color-black;
            }
        }
    }
}

.SelectFieldList .StickyContentWrapper
{
    max-height: 200px;
    overflow: auto;
}