@import "../../../../../Import/SCSS/classes";

.WidgetDropdown .ScrollViewContentWrapper
{
    padding: 5px;
    box-sizing: border-box;
}

.WidgetDropdownSegment
{
    position: relative;
    border-bottom: 1px solid currentColor;

    &:last-child
    {
        border-bottom: none;
    }

    &.Expanded
    {
        .WidgetDropdownSegmentToggle .Icon
        {
            transform: translateY(-45%) rotate(180deg);
        }
    }
}

.WidgetDropdownSegmentContent
{
    padding: 10px 0 20px;
}

.WidgetDropdownSegmentContentButtons
{
    margin-top: 20px;
}

.WidgetDropdownSegmentContentImage
{
    position: relative;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
}

.WidgetDropdownSegments
{
    position: absolute;
    top: $h-field;
    right: 0;
    bottom: 0;
    left: 0;
}

.WidgetDropdownSegmentToggle
{
    @include button-behaviour;

    position: relative;
    line-height: $h-field;

    .Icon
    {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1.8em !important;
        height: 1.8em !important;
        transform: translateY(-50%);
        transition: transform $duration-quick $timing-in-out;
    }
}

.WidgetDropdownTitle
{
    position: relative;
    height: $h-field;
    padding-left: 5px;
    line-height: $h-field;
    z-index: 2000;
    text-align: justify;
    white-space: nowrap;

    h3
    {
        z-index: 1;
    }
}