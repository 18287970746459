@import "../../../../Import/SCSS/classes";

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

*
{
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );
}

body,
input,
select,
textarea
{
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.content-block,
.cb
{
    position: relative;
    min-height: 33px;
    font-size: $font-size;
    box-sizing: border-box;

    a
    {
        color: inherit;
    }

    b,
    strong
    {
        font-weight: $font-weight-bold;
    }

    em,
    i
    {
        font-style: italic;
    }

    h1, h2, h3, h4, h5, h6
    {
        clear: both;
    }

    h1
    {
        @include heading-1;
    }

    h2
    {
        @include heading-2;
    }

    h3
    {
        @include heading-3;
    }

    ol
    {
        counter-reset: list-number;

        li
        {
            position: relative;
            padding-left: 20px;
            counter-increment: list-number;

            &:before
            {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                content: counter(list-number) ". ";
            }
        }
    }

    ul
    {
        li
        {
            position: relative;
            padding-left: 20px;

            &:before
            {
                display: block;
                position: absolute;
                top: 0.55em;
                left: 0.15em;
                width: 0.25em;
                height: 0.25em;
                border-radius: 100%;
                border: 1px solid currentColor;
                content: "";
            }
        }
    }
    
    blockquote
    {
        display: block;
        position: relative;
        margin: 40px 0;
        font-size: 2em;
        line-height: 1.3;
        quotes: "“" "”" "‘" "’";

        &:after
        {
            content: close-quote;
        }

        &:before
        {
            content: open-quote;
        }

        *
        {
            display: inline;
            margin: 0;
        }
    }

    p
    {
        margin: 22px 0;
    }

    p + span
    {
        margin-top: -22px;
    }

    span + p
    {
        margin-top: 0;
    }

    pre
    {
        display: block;
        position: relative;
        margin: 0;
        padding: 10px;
        font-family: 'Courier New', Courier, monospace;
        font-size: 0.8em;
        line-height: 1.6;
        opacity: 0.75;
        overflow: hidden;
        
        &:after,
        &:before
        {
            @include overlay;

            pointer-events: none;
            content: "";
        }

        &:after
        {
            border: 1px solid currentColor;
            opacity: 0.2;
        }

        &:before
        {
            background-color: currentColor;
            opacity: 0.05;
        }
    }

    .cb-br
    {
        position: relative;
        height: $line-height;
    }

    @include responsive-width(800px)
    {
        width: 100% !important;
    }
}

.content-block-background,
.cb-bg
{
    position: relative;
}

.content-block-background.cb-fit,
.cb-bg.cb-fit
{
    padding: 0 !important;
}

.cb-grid
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include responsive-width(800px)
    {
        display: block;
    }
}

.cb-fixed .content-block-background-image-wrapper,
.cb-fixed .cb-bg-iw
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img
    {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.cb-fit .content-block-background-image-wrapper,
.cb-fit .cb-bg-iw
{
    position: relative;

    img
    {
        display: block;
        width: 100%;
        height: auto;
    }
}

.cb-fit .cb-cn
{
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.content-block-background-overlay,
.cb-bg-o
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, color-black(.5), color-black(.1));
}

.content-block-button,
.cb-bt
{
    @include button-behaviour;

    display: block;
    position: relative;
    padding: 10px 20px;
    font-weight: 600;
    text-decoration: none;

    &.align-center,
    &._ac
    {
        margin: 0 auto;
    }

    &.align-left,
    &._al
    {
        margin: 0;
    }

    &.align-right,
    &._ar
    {
        margin: 0 0 0 auto;
    }
}

.content-block-button-container,
.cb-bt-ct
{
    display: flex;
    width: 100%;
    margin: 40px 0 0;
}

.content-block-button-round,
.cb-bt-r
{
    @include button-behaviour;

    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 40px;
    box-sizing: border-box;

    .Icon
    {
        width: 100% !important;
        height: 100% !important;
    }
}

.content-block-content,
.cb-cn
{
    position: relative;
    width: 100%;
    z-index: 1;

    &:after
    {
        display: block;
        clear: both;
        content: "";
    }
}

.content-block-image,
.cb-img
{
    display: block;
    width: 100%;

    &[align="left"]
    {
        clear: left;
        float: left;
        width: calc(50% - 10px);
        margin-right: 10px;
    }

    &[align="right"]
    {
        clear: right;
        float: right;
        width: calc(50% - 10px);
        margin-left: 10px;
    }

    &[align="left"],
    &[align="right"]
    {
        @include responsive-width(800px)
        {
            float: none !important;
            width: 100% !important;
            margin: 0 0 10px !important;
        }
    }
}

.expandables-block,
.ex
{
    &._ac
    {
        .ex-t
        {
            text-align: center;
        }
    }

    &._al
    {
        .ex-t
        {
            text-align: left;
        }
    }

    &._ar
    {
        .ex-t
        {
            text-align: right;
        }
    }
}

.expandables-block-section,
.ex-sn
{
    padding: 0 40px;
}

.expandables-block-section-chevron,
.ex-sn-c
{
    transition: transform $duration $timing-in-out;

    .expandables-block-section[data-expand="1"] &,
    .ex-sn[data-expand="1"] &
    {
        transform: rotate(180deg);
    }
}

.expandables-block-section-clip,
.ex-sn-cl
{
    position: relative;
    height: 0;
    overflow: hidden;

    .expandables-block-section[data-expand="1"] &,
    .ex-sn[data-expand="1"] &
    {
        height: auto;
    }
}

.expandables-block-section-content-wrapper,
.ex-sn-cw
{
    position: relative;
    padding: 20px 0 40px;
    overflow: hidden;
}

.expandables-block-sections,
.ex-sns
{
    padding: 60px 0;
}

.expandables-block-section-image,
.ex-sn-i
{
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.expandables-block-section-label,
.ex-sn-l
{
    font-weight: 600;
}

.expandables-block-section-toggle,
.ex-sn-t
{
    @include button-behaviour(1);

    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    &:after
    {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        height: 1px;
        background-color: currentColor;
        opacity: 0.18;
        content: "";
    }

    .expandables-block-section:last-child &:after,
    .ex-sn:last-child &:after
    {
        opacity: 0;
    }

    .expandables-block-section[data-expand="1"] &:after,
    .ex-sn[data-expand="1"] &:after
    {
        opacity: 0.18;
    }
}

.expandables-block-section-wrapper,
.gallery-block-slide-content-wrapper,
.gallery-block-top,
.html-block.has-padding .html-block-content,
.tabs-block-tab-content-wrapper,
.tabs-block-top,
.text-block-content,
.top-block-content,
.video-block-player-wrapper,
.ex-sn-w,
.gl-sl-cw,
.gl-tp,
.ht._hp .ht-cn,
.ln-tp,
.tb-tb-cw,
.tb-tp,
.tx-cn,
.tp-cn,
.vd-pl-w
{
    //max-width: 645px;
    max-width: 1200px;
    margin: 0 auto;
}

.expandables-block-top,
.gallery-block-top,
.tabs-block-top,
.video-block-top,
.ex-tp,
.gl-tp,
.ln-l,
.ln-tp,
.tb-tp,
.vd-tp
{
    padding: 40px 40px;
    text-align: center;

    .has-items &,
    ._hi &
    {
        padding-bottom: 0;
    }

    h2
    {
        //max-width: 645px;
        max-width: 1200px;
        margin: 0 auto;
    }
}

.gallery-block,
.gl
{
    &._ac
    {
        .gl-t
        {
            text-align: center;
        }
    }

    &._al
    {
        .gl-t
        {
            text-align: left;
        }
    }

    &._ar
    {
        .gl-t
        {
            text-align: right;
        }
    }

    &.has-dots,
    &._hd
    {
        .gallery-block-slide,
        .gl-sl
        {
            padding-bottom: 40px;
        }
    }
}

.gallery-block-slide,
.html-block.has-padding,
.tabs-block-tab,
.text-block,
.gl-sl,
.ht._hp,
.tb-tb,
.tx
{
    padding: 40px 40px;

    &:after
    {
        display: block;
        clear: both;
        content: "";
    }
}

.gallery-block-slide,
.tabs-block-tab,
.gl-sl,
.tb-tb
{
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.gallery-block-nav-arrow,
.gl-nv-a
{
    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;

    &.nav-left,
    &._nl
    {
        left: 20px;
    }

    &.nav-right,
    &._nr
    {
        right: 20px;
    }
}

.gallery-block-nav-dot,
.gl-nv-d
{
    @include button-behaviour;

    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;
    margin: 0 5px;

    &:before,
    .gallery-block-nav-dot-fill,
    .gl-nv-df
    {
        @include overlay;
        border-radius: 10px;
    }

    &:before
    {
        background-color: currentColor;
        opacity: 0.125;
        content: "";
    }

}

.gallery-block-nav-dot-fill,
.gl-nv-df
{
    display: none;

    .gallery-block-nav-dot[data-active="1"] &,
    .gl-nv-d[data-active="1"] &
    {
        display: block;
    }
}

.gallery-block-nav-dots,
.gl-nv-ds
{
    height: 10px;
    padding: 0 0 40px;
    text-align: center;
    line-height: 10px;
}

.gallery-block-slide,
.gl-sl
{
    pointer-events: none;
    opacity: 0;

    &[data-active="1"],
    .gallery-block-initiated &,
    .gl-in &
    {
        pointer-events: auto;
        opacity: 1;
    }
}

.gallery-block-slide-content,
.gl-sl-cn
{
    width: 100%;
}

.gallery-block-slide-content-wrapper,
.gl-sl-cw
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.gallery-block-slide-image,
.tabs-block-tab-image,
.gl-sl-i,
.tb-tb-i
{
    
    display: block;
    width: 100%;

    .has-fixed-height &,
    ._fh &
    {
        @include overlay;

        height: 100%;
        object-fit: cover;
    }
}

.gallery-block-slide-image-wrapper,
.tabs-block-tab-image-wrapper,
.gl-sl-iw,
.tb-tb-iw
{
    position: relative;
    width: 100%;
    margin: 0 0 20px;
}

.gallery-block-slides,
.tabs-block-tabs,
.gl-sls,
.tb-tbs
{
    overflow: hidden;
}

.gallery-block-slides-wrapper,
.gl-sls-w
{
    display: flex;
}

.links-block,
.ln,
.tabs-block,
.tb
{
    &._ac
    {
        .ln-t,
        .tb-t
        {
            text-align: center;
        }
    }

    &._al
    {
        .ln-t,
        .tb-t
        {
            text-align: left;
        }
    }

    &._ar
    {
        .ln-t,
        .tb-t
        {
            text-align: right;
        }
    }
}

.links-block,
.ln
{
    margin: 0 auto;

    &._layout_2_col .ln-l-a-w
    {
        width: calc(50% - 10px);

        @include responsive-width(400px)
        {
            width: 100%;
        }
    }

    &._layout_3_col .ln-l-a-w
    {
        width: calc(33.3333% - 15px);

        @include responsive-width(700px)
        {
            width: calc(50% - 10px);
        }

        @include responsive-width(400px)
        {
            width: 100%;
        }
    }
}

.links-content,
.ln-cn
{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;

    .cb-bt
    {
        width: fit-content;
        margin-top: 20px;
        margin-left: auto;
    }

    @include responsive-width(800px)
    {
        flex-direction: column;
    }
}

.links-list,
.ln-l
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: stretch;
    gap: 20px;
    padding-top: 0;

    .cb-bg,
    .cb-cn
    {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    @include responsive-width(800px)
    {
        padding: 0;
        max-width: none;
    }
}

.ln-l-a
{
    @include overlay;
    @include no-select;

    display: block;
    opacity: 0;
    z-index: 1000;
}

.ln-l-a-bg
{
    padding: 10px;
    border-radius: 4px;

    &:after
    {
        z-index: 2;
    }

    &:before
    {
        z-index: 3;
    }

    .cb-cn
    {
        z-index: 1;
    }
}

.ln-l-a-w
{
    @include button-behaviour(1);
    @include fade-up;

    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
    text-decoration: none;
    box-sizing: border-box;

    h4,
    .ln-l-t
    {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
    }

    .ln-l-t
    {
        *
        {
            margin: 0 !important;
        }
    }

    .cb-bt
    {
        transform: scale(1) !important;
    }
}

.ln-l-a-bg,
.links-navigation-taxonomy-list > div,
.ln-nv-t-l-cb
{
    &:after
    {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background-color: currentColor;
        border-radius: 4px;
        opacity: 0.0625;
        pointer-events: none;
        content: "";
        transition: all .25s ease;
    }

    &:before
    {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border: 1px solid currentColor;
        border-radius: 4px;
        opacity: 0.18;
        pointer-events: none;
        content: "";
    }
}

.link-link-content,
.ln-l-cn
{
    flex-grow: 1;
}

.links-link-info,
.ln-l-i
{
    position: relative;
    margin-top: 20px;
    padding-top: 10px;
    font-size: 0.9em;
    font-weight: 400;
    text-align: center;

    &:before
    {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 1px;
        background-color: currentColor;
        content: "";
        opacity: 0.25;
    }

    p
    {
        margin: 0 !important;
    }
}

.links-navigation,
.ln-nv
{
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 200px;
    align-items: stretch;
    text-align: left;
    gap: 20px;
    padding: 0 0 60px;

    @include responsive-width(800px)
    {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        max-width: none;

        .ln-nv-tx
        {
            width: 100%;
            margin-bottom: 15px;
        }
    }
}

.links-navigation-taxonomy,
.ln-nv-t
{
    flex-grow: 1;
}

.links-navigation-taxonomy-description,
.ln-nv-t-d
{
    padding: 8px 0 4px;
    font-size: 0.85em;
}

.links-navigation-taxonomy-list,
.ln-nv-t-l
{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 10px;
    gap: 5px;

    @include responsive-width(800px)
    {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.links-navigation-taxonomy-list-item,
.ln-nv-t-l-i
{
    @include no-select;

    display: flex;
    position: relative;
    align-items: center;
    min-height: 25px;
    padding: 0 7px;
    font-size: 0.9em;
    line-height: 1.2;
    cursor: pointer;

    &[data-active="1"]
    {
        font-weight: 600;

        &:after
        {
            opacity: 0;
        }

        .ln-nv-t-l-cb
        {
            &:after
            {
                top: 7px;
                right: 7px;
                bottom: 7px;
                left: 7px;
                opacity: 1;
            }
        }
    }
}

.links-navigation-taxonomy-list-checkbox,
.ln-nv-t-l-cb
{
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    content: "";
}

.links-navigation-taxonomy-name,
.ln-nv-t-n
{
    @include no-select;

    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid currentColor;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.5;
}

.links-title,
.ln-t
{
    margin-bottom: 60px !important;
}

.links-top,
.ln-tp
{
    padding-bottom: 0;
}

.preview-wrapper,
.preview-wrapper-fullwidth,
.pw-w,
.pw-w-fw
{
    position: relative;
    margin: 20px auto;
    background-color: $color-white;
    border: 1px solid #d1d1d1;
}

.preview-wrapper,
.pw-w
{
    max-width: 938px;
}

.tabs-block-nav,
.tb-nv
{
    display: flex;
    margin-top: 20px;
}

.tabs-block-nav-item,
.tb-nv-i
{
    @include button-behaviour(1);

    display: block;
    position: relative;
    padding: 5px 10px;
    font-weight: 400;

    &:after
    {
        @include overlay;

        display: block;
        background-color: currentColor;
        border-radius: 4px 4px 0 0;
        opacity: 0.0625;
        pointer-events: none;
        content: "";
    }

    &:before
    {
        @include overlay;

        display: block;
        border: 1px solid currentColor;
        border-radius: 4px 4px 0 0;
        opacity: 0.18;
        pointer-events: none;
        content: "";
    }

    &[data-active="1"]
    {
        font-weight: 600;

        &:after
        {
            display: none;
        }

        &:before
        {
            border-bottom: none;
        }
    }
}

.tabs-block-nav-items,
.tb-nv-is
{
    display: flex;
    width: calc(100% - 80px);
    max-width: 645px;
}

.tabs-block-nav-margin,
.tabs-block-nav-padding,
.tb-nv-m,
.tb-nv-p
{
    position: relative;
    border-bottom: 1px solid currentColor;
    opacity: 0.18;

    &:after
    {
        @include overlay;

        content: "";
        background-color: currentColor;
        mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), 10%, rgba(0, 0, 0, 0));
    }
}

.tabs-block-nav-margin,
.tb-nv-m
{
    flex-grow: 1;
    //width: 40px;
}

.tabs-block-nav-padding,
.tb-nv-p
{
    width: 2px;

    &:last-child
    {
        width: auto;
        flex-grow: 1;
    }
}

.tabs-block-tab,
.tb-tb
{
    display: none;
    position: relative;

    &[data-active="1"]
    {
        display: block
    }
}

.tabs-block-tabs-wrapper,
.tb-tb-w
{
    position: relative;
}

.text-block-image,
.tx-i
{
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 40px;
}

.top-block,
.tp
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 200px;
    padding: 40px;
    text-align: center;

    h1
    {
        margin: 0;
    }

    &._ac
    {
        .top-block-content,
        .tp-cn
        {
            align-items: center;
            text-align: center;
        }
    }

    &._al
    {
        .top-block-content,
        .tp-cn
        {
            align-items: start;
            text-align: left;
        }
    }

    &._ar
    {
        .top-block-content,
        .tp-cn
        {
            align-items: end;
            text-align: right;
        }
    }
}

.top-block-content,
.tp-cn
{
    display: flex;
    flex-direction: column;

    & > *
    {
        display: flex;
    }
}

.top-block-read-time,
.tp-rt
{
    @include no-select;
    
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    font-weight: $font-weight-bold;
    border-radius: 3px 0 0 0;
}

.video-block,
.vd
{
    &._ac
    {
        .vd-t
        {
            text-align: center;
        }
    }

    &._al
    {
        .vd-t
        {
            text-align: left;
        }
    }

    &._ar
    {
        .vd-t
        {
            text-align: right;
        }
    }
}

.video-block-player,
.vd-pl
{
    @include overlay;
}

.video-block-player-container,
.vd-pl-ct
{
    position: relative;
    padding: 0 0 56.25%;
    background-color: color-black(0.18);
}

.video-block-player-element,
.vd-pl-e
{
    @include overlay;
}

.vd-pl-p
{
    @include content-background-overlay;
    @include overlay;

    .cb-cn
    {
        @include overlay;

        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        color: $color-white;
    }
}

.vd-pl-pp
{
    @include button-behaviour;

    position: relative;
    left: 8px;
    width: 80px !important;
    height: 80px !important;
}

.video-block-player-wrapper,
.vd-pl-w
{
    padding: 0 40px 60px;
}

.fuse-player
{
    @include overlay;
    
    overflow: hidden;
    color: $color-white;
    background-color: $color-black;

    video
    {
        @include overlay;

        display: block;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &:hover
    {
        .fuse-player-controls
        {
            transform: translateY(0) !important;
            opacity: 1 !important;
        }
    }
}

.fuse-player-button
{
    @include button-behaviour;

    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 4px;
    vertical-align: middle;

    svg
    {
        display: block;
        width: 100%;
        height: 100%
    }
}

.fuse-player-controls
{
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 8px;
    opacity: 0;
    transform: translateY(100%);
    transition: all $duration-quick $timing-in-out;
    background: linear-gradient(0deg, color-black(0.5), color-black(0));
    z-index: 10;
}

.fuse-player-progress
{
    position: relative;
    height: 4px;
    margin: 5px;
    background: color-white(0.5);
    border-radius: 4px;
    overflow: hidden;
    flex-grow: 1;
}

.fuse-player-progress-fill
{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: inherit;
}

.fuse-player-progress-left,
.fuse-player-progress-right
{
    position: relative;
    top: -1px;
    height: 14px;
    min-width: 30px;
    color: currentColor;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.fuse-player-progress-left
{
    padding-right: 5px;
    text-align: right;
}

.fuse-player-progress-right
{
    padding-left: 5px;
    text-align: left;
}

.fuse-player-progress-wrapper
{
    @include no-select;
    
    display: flex;
    position: relative;
    align-items: center;
    height: 32px;
    padding: 14px;
    flex-grow: 1;
    box-sizing: border-box;
}