
@import '../../../../Import/SCSS/classes';

.ListField {

    @include field;

    position: relative;

    label {

        @include field-label;

    }

}

.ListFieldItem {

    @include item;

    position: relative;
    cursor: move;

    &:after {

        @include overlay;

        display: block;
        background: linear-gradient( 90deg, color-white(0) 50%,  color-white(1) );
        content: "";

    }

    &:first-child .ListFieldItemIcons {

        top: 7px;

    }

    &.Active {

        background-color: $color-gray !important;
        box-shadow: $box-shadow;
        opacity: 0.5;
        z-index: 10;

        &:after {

            opacity: 0;

        }

    }

    &.Highlight {

        background-color: color-black(0.1);

    }

    &.Hover:after,
    &.HoverLast:after {

        display: block;
        position: absolute;
        top: -1px;
        right: 0;
        left: 0;
        height: 2px;
        background: $color-black;
        content: "";

    }

    &.HoverLast:after {

        top: auto;
        bottom: -1px;

    }

}

.ListFieldItemIcons {

    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 10;

}

.ListFieldItems {

    position: relative;
    color: $color-black;
    background-color: $color-white;

}