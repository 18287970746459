
@import '../../../Import/SCSS/classes';

.IconItem {

    @include no-select;

    display: block;
    position: relative;
    margin: 5px 0;
    cursor: pointer;

    .Icon {

        @include button-transition;

    }

    @include hover {

        .Icon {

            @include button-hover( 1.3 );

        }

    }

    @include active {

        .Icon {

            transform: scale( 1 ) !important;

        }

    }

    &.Disabled {

        opacity: 0.5;

        .Icon {

            transform: scale( 1 ) !important;

        }

    }

}

.IconItemLabel {

    display: inline-block;
    position: relative;
    margin-left: 5px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    vertical-align: middle;

}