
@import '../../../../Import/SCSS/classes';

$left-width: 145px;

.PermissionField {

    position: relative;

    label {

        @include field-label;

    }

    .ContentField .Input {

        width: 100%;
        padding-left: 0;
        font-style: italic;

    }

    .ContentFieldContent .ContentFieldItem {

        padding-left: 0;

    }

    .ContentFieldIcon,
    .ContentFieldItemRemove {

        display: none !important;

    }

    .SelectField .Input {

        min-width: 0 !important;

    }

}

.PermissionFieldDefault {

    @include item;

    position: relative;
    color: color-black( 0.5 );
    font-style: italic;

}

.PermissionFieldItem {

    @include item;

    position: relative;
    padding: 0 !important;
    color: color-black( 0.5 );
    font-style: italic;
    overflow: visible;

    &.Focus .PermissionFieldItemRemove {

        z-index: 5000;

    }

    .Field {

        margin: 0 !important;

    }

}

.PermissionFieldItemRemove {

    position: absolute;
    top: 13px;
    right: 10px;

}

.PermissionFieldLeft {

    display: inline-block;
    position: relative;
    width: $left-width;
    height: $h-field;
    padding: 0 0 0 10px;
    line-height: $h-field + 1px;
    box-sizing: border-box;
    vertical-align: top;

}

.PermissionFieldLeftSelect {

    display: inline-block;
    position: relative;
    width: $left-width;
    box-sizing: border-box;
    vertical-align: top;

}

.PermissionFieldList {

    position: relative;
    color: $color-black;
    background-color: $color-white;

}

.PermissionFieldRight {

    display: inline-block;
    position: relative;
    width: calc( 100% - #{$left-width} );
    vertical-align: top;

}