@import "../../../Import/SCSS/classes";

$wrapper-shadow-opacity: 0.05;

.WidgetWrapper
{
    @include overlay;

    &.Grayscale .WidgetWrapperBackground
    {
        @include grayscale;
    }

    &.HasBackgroundOverlay
    {
        .WidgetWrapperBackground
        {
            @include content-background-overlay;
        }
    }
}

.WidgetWrapperBackground
{
    @include content-background;
}

.WidgetWrapperContent
{
    top: $h-field;
}

.WidgetWrapperTitle
{
    position: relative;
    height: $h-field;
    padding-left: 5px;
    line-height: $h-field;
    z-index: 2000;
    text-align: justify;
    white-space: nowrap;

    h3
    {
        z-index: 1;
    }
}

.WidgetWrapperToolbar
{
    position: absolute;
    top: 0;
    right: 0;

    .FilterFieldInput,
    .TextField .Input
    {
        min-width: 210px !important;
        color: inherit !important;
        background-color: transparent !important;
        font-style: italic;

        @include input-placeholder
        {
            color: inherit !important;
        }
    }

    .Link
    {
        @include heading(0.875em);
        @include no-select;

        display: inline-block;
        margin-right: 10px;
        opacity: 0.5;

        @include hover
        {
            opacity: 1;
        }
    }

    .TextField .Input
    {
        opacity: 0.5;

        &:focus
        {
            opacity: 1;
        }
    }

    .TextField .Icon
    {
        color: inherit !important;
    }
}