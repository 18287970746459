@import "../../../../../Import/SCSS/classes";

.WidgetAskText
{
    margin: 0 0 32px;
}

.WidgetAskConfirm
{
    @include fade-up;
    @include overlay;
    background-color: color-gray-light(0.9);

    span
    {
        display: block;
        position: relative;
        top: 50%;
        font-family: $font-family-headline;
        font-weight: $font-weight-headline;
        text-align: center;
        transform: translateY(-50%);
    }
}